@import "src/global/variables";
@import "src/global/grid.scss";
@import "src/global/typography.scss";

.about-host {
  background-color: $c-gray-0;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @include media-breakpoint-up("md") {
    flex-direction: row;
    margin-bottom: 140px;
  }

  &__carousel {
    overflow: hidden;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include media-breakpoint-up("md") {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 8px;
      width: 45%;
    }
  }

  &__carousel-inner {
    width: 100%;
    white-space: nowrap;
    transition: transform 0.3s;
  }

  &__image-wrapper {
    width: 100%;
    display: inline;
  }

  &__image {
    width: 100%;
    height: 300px;

    @include media-breakpoint-up("sm") {
      height: 800px;
    }
  }

  &__main {
    position: relative;
    width: 100%;
    padding: 56px $grid-padding-mobile;

    @include media-breakpoint-up("md") {
      padding: 77px $grid-padding-desktop;
      width: 55%;
    }
  }

  &__item {
  }

  &__item-title {
    @extend %new-h1;

    margin-bottom: 48px;
  }

  &__item-description {
    @extend %new-paragraph;

    padding-bottom: 77px;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translateX(-50%);
    padding-left: $grid-padding-mobile;
    padding-right: $grid-padding-mobile;

    @include media-breakpoint-up("md") {
      padding-left: $grid-padding-desktop;
      padding-right: $grid-padding-desktop;
      bottom: 77px;
    }
  }

  &__numbers {
    @extend %new-paragraph;

    color: $color-grey3;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $c-white;
    box-shadow: 0 8px 11px rgba(97, 63, 53, 0.1),
      0 8px 150px rgba(97, 63, 53, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      margin-right: 16px;

      .about-host__button-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__button-icon {
    width: 26px;
    height: 26px;
  }
}
