@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.adc {
  &__block {
    padding: 20px 0;

    &:first-child {
      padding-top: 40px;
    }
  }

  &__header {
    margin-bottom: 16px;

    @extend %new-h3;
  }

  &__header-number {
    position: relative;
    margin-left: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    background-color: $c-black;
    color: $c-white;

    @extend %font-primary-medium;

    @include media-breakpoint-up("md") {
      bottom: 12px;
    }
  }

  &__requests-wrapper,
  &__scheduled-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .adc__booking-card {
      width: 100%;
      margin-bottom: 16px;

      @include media-breakpoint-up("md") {
        width: calc((100% / 3) - 16px);
      }
    }
  }
}