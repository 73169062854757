@import "src/global/typography";

.no-conversations {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 100%;

  &__icon {
    opacity: 0.2;
    height: 11 * $mainsize;
    width: 11 * $mainsize;
    margin-bottom: 4 * $mainsize;
  }

  &__title,
  &__subtitle {
    display: flex;
    justify-content: center;
  }

  &__title {
    margin-bottom: 2 * $mainsize;

    @extend %new-h3;
  }

  &__subtitle {
    margin-bottom: 4 * $mainsize;
    max-width: 33 * $mainsize;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @extend %new-paragraph;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-down("sm") {
  .no-messages {
    &__icon {
      height: 8 * $mainsize;
      width: 8 * $mainsize;
    }
  }
}
