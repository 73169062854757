@import "src/global/variables";
@import "src/global/typography";

.container {
  opacity: 0.8;
  display: flex;
  align-items: center;
  color: inherit;

  &.sm {
    @extend %text-small;

    > :first-child {
      margin-right: 6px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.md {
    @extend %text-small;

    > :first-child {
      margin-right: 8px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.lg {
    @extend %h3-desktop;

    font-weight: bold;

    > :first-child {
      margin-right: 12px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
