@import "src/global/variables";
@import "src/global/typography";

.locationFilterBox {
  padding: 4px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width:100%;

  .locationLabel {
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $c-black;
    line-height:1;
    margin-bottom:2px;
  }

  .input, .inputPlaceholder {
    font-size: 1.6rem;
    color: #2b2e3f;
    opacity: 0.5;
    width: 100%;
    outline: none;
    border: none;
  }
  .inputPlaceholder{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 290px;
    width:100%;
  }

  .autocompleteListContainer{
    position: relative;
    width:100%;
  }
  .autocompleteList {
    top:15px;
    left: -15px;
    min-width: 250px;
    position: absolute;
    background: $c-white;
    border: 1px solid $c-semi-light-gray;
    border-radius: 8px;
    padding: 4px 0;
    list-style-type: none;
    z-index: 100;
    text-align: center;
    font-size:13px;

    li {
      text-align: left;
      padding: 12px 16px;
      white-space: nowrap;
      &:hover {
        background-color:$c-light-gray-2;
        cursor: pointer;
      }
    }
  }

  .inputTrigger {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  }

  .inputWrapper {
    position: relative;
    flex:1 0 100%;
    width:100%;
    display:flex;
  
  }

  &.locationFilterBoxDisabled {
    .input {
      pointer-events: none;
    }
  }
  .icon{
    width:12px;
    height:12px;
    flex: 0 0 12px;
    color:$c-gray;
  }
}
