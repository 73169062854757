@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';
.top-banner {
    &__container {
        display: flex;
        flex-direction: column;

        max-width: 500px;
        width: 100%;
        margin: 0;
    }

    &__row {
        max-width: 500px;
        width: 100%;
        & + .top-banner__row {
            margin-top: 5 * $mainsize;
        }
    }

    &__form-subheader {
        font-size: 1.2rem;
        line-height: 0.9rem;
        text-transform: uppercase;
        color: $c-dark-orange;
        margin-bottom: 8px;
    }

    &__form-header {
        @extend %font-secondary-bold;
        font-size: 3.2rem;
        line-height: 3.2rem;
        margin-bottom: 16px;
    }

    &__form-description {
        font-size: 1.5rem;
        line-height: 1.9rem;
        opacity: 0.6;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__button-register {
        width: 100%;
        margin-bottom: 8px;
    }

    @include media-breakpoint-up('md') {
        min-height: 100vh;
        padding-top: 160px;
        padding-bottom: 130px;
        &__container {
            padding: 3 * $mainsize;
            background-color: $c-white;
            border-radius: 8px;
        }
    }

    @include media-breakpoint-down('sm') {
        &__container {
            min-height: 100vh;
            padding-top: 80px;
            padding-bottom: 40px;
            color: $c-white;
        }
        &__row {
            &--copy {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 0 50%;
            }
            &--form {
                margin-top: auto;
                flex: 0 0 50%;
                align-self: flex-end;
                background-color: $c-white;
                border-radius: 8px;
                padding: 3 * $mainsize;
            }
        }
    }
}

// .mainPage {
//   min-height: 100vh;
//   min-width: 100vw;

//   .topContainer {
//     background-size: cover;
//   }

//   .sectionContainer {
//     padding-left: 0;
//     padding-right: 0;
//     padding-bottom: 0;
//     max-width: 100%;
//   }

//   .bannerContainer {
//     position: relative;

//     img {
//       width: 100%;
//     }
//   }

//   .signupForm {
//     width: 100%;
//     padding: 120px 100px 40px 100px;

//     @include media-breakpoint-down("sm") {
//       position: unset;
//       padding: 20px 30px;
//     }

//     .formContainer {
//       background: $color-white;
//       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
//       border-radius: 8px;
//       box-sizing: border-box;
//       border-radius: 8px;
//       padding: 20px;

//       @include media-breakpoint-down("sm") {
//         max-width: 700px;
//         margin: 70px auto;
//       }

//       .headingText {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 13px;
//         line-height: 100%;
//         letter-spacing: 0.04em;
//         color: #d25034;
//         margin-bottom: 15px;
//       }

//       .heading {
//         font-family: Rokkitt, sans-serif;
//         font-style: normal;
//         font-weight: 900;
//         font-size: 38px;
//         color: #222720;
//         line-height: 90%;
//         margin: 15px 0;
//       }

//       .description {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: normal;
//         font-size: 14px;
//         line-height: 175%;
//         color: #1e201e;
//       }

//       .form {
//         margin: 20px 0;

//         .formInput {
//           width: 100%;
//           border-radius: 4px;
//           font-size: 16px;
//           color: #a9afa8;
//           margin: 15px 0 0;
//           border: 1px solid rgba(15, 56, 6, 0.3);
//           box-sizing: border-box;
//           padding: 10px 10px 10px 15px;

//           &:focus,
//           &:active {
//             outline: none;
//           }

//           input {
//             background-color: $color-white;
//           }

//           label {
//             font-size: 20px;
//             line-height: 14px;
//             color: #a9afa8;
//           }

//           .mobileLabel {
//             margin-left: 20px;
//           }
//         }

//         .inputContainer {
//           position: relative;

//           .passwordInput {
//             padding-right: 50px;
//           }

//           .mobileIcon {
//             position: absolute;
//             left: 0;
//             top: 15px;
//             border-right: 1px solid rgba(15, 56, 6, 0.3);
//             height: 42px;

//             button {
//               padding: 10px;
//             }
//           }

//           .passwordVisibilityIcon {
//             position: absolute;
//             right: 0;
//             top: 15px;
//             // border-left: 1px solid rgba(15, 56, 6, 0.3);
//             height: 42px;
//             // background: #eeeeee;
//             border-radius: 0 3px 3px 0;

//             button {
//               padding: 10px;
//             }
//           }
//         }

//         :global {
//           .Mui-error {
//             margin: 0;
//             font-weight: bold;
//           }
//         }

//         .infoText {
//           font-family: $ff-default;
//           font-style: normal;
//           font-weight: normal;
//           font-size: 13px;
//           line-height: 23px;
//           text-align: center;
//           color: #757575;

//           a {
//             font-weight: bold;
//           }
//         }

//         .submitBtn,
//         .submitBtn:hover {
//           background: #d25034;
//           border: 1px solid #a53820;
//           box-sizing: border-box;
//           color: $color-white;
//           border-radius: 50px;
//           margin: 25px 0;
//           text-transform: unset;
//           padding: 8px 0;
//           font-family: $ff-default;
//           font-style: normal;
//           font-weight: 800;
//           font-size: 18px;
//           line-height: 24px;

//           /* identical to box height, or 133% */

//           text-align: center;
//           letter-spacing: 0.59997px;

//           .loadingIcon {
//             border-color: $color-white;
//             border-left-color: transparent;
//           }

//           &:disabled {
//             cursor: not-allowed;
//           }
//         }
//       }
//     }
//   }

//   .howItWorks {
//     margin: 50px auto;

//     .title {
//       color: #000;
//       font-family: "Rokkitt", sans-serif;
//       text-align: center;
//       font-size: 44px;
//       margin-bottom: 50px;
//     }

//     .blockContainer {
//       padding: 0 60px;

//       .heading {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 32px;
//         line-height: 33px;
//         color: #d25034;
//         margin-bottom: 15px;
//       }

//       .head {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 20px;
//         line-height: 24px;
//         color: #080908;
//         text-align: left;
//         margin-bottom: 15px;
//       }

//       .description {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: normal;
//         font-size: 16px;
//         line-height: 28px;
//         color: #1e201e;
//         margin-bottom: 15px;
//       }
//     }
//   }

//   .imageContainer {
//     max-width: 90%;
//     margin: -50px auto 0;
//     text-align: center;

//     img {
//       max-width: 100%;
//     }
//   }

//   .faqSection {
//     background: #a53820;
//     border-radius: 0;
//     margin: 100px 0;
//     padding: 120px 0;
//     box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

//     @include media-breakpoint-down("sm") {
//       margin-top: 0;
//       padding: 50px 0;
//     }

//     .faqHeading {
//       width: 100%;
//       font-family: Rokkitt, sans-serif;
//       font-style: normal;
//       font-weight: 900;
//       font-size: 40px;
//       line-height: 33px;
//       color: #fff;
//       margin-bottom: 30px;
//     }

//     .faqBox {
//       border-bottom: 2px solid rgba(255, 255, 255, 0.24);
//       padding: 20px 0;
//       max-width: 85%;
//       font-family: $ff-default;
//       font-style: normal;
//       font-weight: 800;
//       font-size: 15px;
//       line-height: 20px;
//       color: #fff;

//       .faqItem {
//         font-family: $ff-default;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         img {
//           margin-right: 15px;
//         }
//       }

//       .answer {
//         p,
//         li {
//           padding: 10px 0;
//           font-family: $ff-default;
//         }

//         ul {
//           list-style-type: none;
//         }
//       }
//     }
//   }

//   .detailsBox {
//     margin-bottom: 50px;

//     h3 {
//       margin-bottom: 20px;
//       font-family: Rokkitt, sans-serif;
//       font-style: normal;
//       font-weight: 800;
//       font-size: 44px;
//       line-height: 50px;
//       color: #000;
//     }

//     .detailItem {
//       padding: 10px;
//       margin: 20px 0;

//       h2 {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 18px;
//         line-height: 25px;
//         color: #000;
//         margin: 20px 0;
//       }

//       p {
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: normal;
//         font-size: 14px;
//         line-height: 200%;
//         color: #000;
//       }
//     }

//     .getStartedBox {
//       margin: 30px auto 0;
//       text-align: center;
//       background: #f9f4f1;
//       border-radius: 0;
//       padding: 90px 0;

//       .info {
//         font-family: Rokkitt, sans-serif;
//         font-style: normal;
//         font-weight: 900;
//         font-size: 48px;
//         line-height: 42px;

//         /* identical to box height, or 87% */

//         text-align: center;
//         color: #000;
//       }

//       .getStartedBtn {
//         background: #d25034;
//         border: 1px solid #a53820;
//         box-sizing: border-box;
//         border-radius: 50px;
//         font-family: $ff-default;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 20px;
//         line-height: 24px;
//         text-align: center;
//         letter-spacing: 0.59997px;
//         color: #fff;
//         margin: 25px 0;
//         text-transform: capitalize;
//         padding: 10px;
//         max-width: 150px;

//         span {
//           font-family: $ff-default;
//           font-size: 16px;
//           color: $color-white;
//           line-height: 26px;
//         }
//       }
//     }
//   }
// }
