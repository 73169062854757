@import './variables.scss';
@import './grid.scss';

@mixin typo-14 {
    font-size: 14px;
    line-height: 17px;
}

@mixin typo-body {
    font-size: 20px;
    line-height: 28px;
}

@mixin typo-small {
    font-size: 16px;
    line-height: 19px;
}

%h2-desktop {
    font-size: 50px;
    line-height: 57px;

    @include media-breakpoint-down('md') {
        font-size: 40px;
        line-height: 40px;
    }
}

%h2-desktop--much-decreasing {
    font-size: 50px;
    line-height: 57px;

    @include media-breakpoint-down('sm') {
        font-size: 20px;
        line-height: 24px;
    }
}

%h3-desktop {
    font-size: 26px;
    line-height: 31px;

    @include media-breakpoint-down('md') {
        font-size: 20px;
        line-height: 24px;
    }
}

%h3-desktop--not-decreasing {
    font-size: 26px;
    line-height: 31px;
}

%text-body {
    font-family: $ff-default;
    font-style: normal;
    font-weight: normal;

    @include typo-body;
}

%text-small {
    font-family: $ff-default;
    font-style: normal;
    font-weight: normal;

    @include typo-small;
}

%work-text-small {
    font-family: $ff-default;
    font-style: normal;
    font-weight: normal;

    @include typo-small;
}

%text-x-small {
    font-family: $ff-default;
    font-style: normal;
    font-weight: normal;

    @include typo-14;
}

%h3-mobile {
    font-size: 20px;
    line-height: 24px;
}

// NEW DESIGN
%font-primary-regular {
    font-family: $ff-default;
    font-weight: 400;
}

%font-primary-medium {
    font-family: $ff-default;
    font-weight: 500;
}

%font-primary-bold {
    font-family: $ff-default;
    font-weight: 700;
}

%font-secondary-bold {
    font-family: $ff-rokkitt;
    font-weight: 700;
}

%font-secondary-extra-bold {
    font-family: $ff-rokkitt;
    font-weight: 900;
}

%new-h1 {
    font-size: 4.8rem;
    line-height: 117%;
    color: $c-black;
    @extend %font-secondary-bold;
    @include media-breakpoint-down('sm') {
        font-size: 2.4rem;
    }
}

%new-h1-sans {
    font-size: 4.8rem;
    line-height: 117%;
    color: $c-black;
    @extend %font-primary-bold;
    @include media-breakpoint-down('sm') {
        font-size: 2.4rem;
    }
}

%new-h2 {
    font-size: 3.2rem;
    line-height: 150%;
    color: $c-black;
    @extend %font-primary-medium;
    @include media-breakpoint-down('sm') {
        font-size: 2.4rem;
    }
}

%new-h3 {
    font-size: 2.4rem;
    line-height: 133%;
    color: $c-black;
    @extend %font-primary-medium;
    @include media-breakpoint-down('sm') {
        font-size: 2rem;
    }
}

%new-h4 {
    font-size: 1.7rem;
    line-height: 141%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
    @include media-breakpoint-down('sm') {
        font-size: 1.5rem;
    }
}

%new-h5 {
    font-size: 1.6rem;
    line-height: 141%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
    @include media-breakpoint-down('sm') {
        font-size: 1.45rem;
    }
}

%new-h6 {
    font-size: 1.5rem;
    line-height: 141%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
    @include media-breakpoint-down('sm') {
        font-size: 1.4rem;
    }
}

%new-small-text {
    font-size: 1.5rem;
    line-height: 107%;
    color: $c-black;
    @extend %font-primary-regular;
}

%new-extra-small-text {
    font-size: 1.3rem;
    line-height: 123%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
}

%new-extra-extra-small-text {
    font-size: 1.2rem;
    line-height: 133%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
}

%new-top-menu {
    font-size: 1.7rem;
    line-height: 141%;
    color: $c-black;
    @extend %font-primary-medium;
}

%new-label {
    font-size: 1.4rem;
    line-height: 114%;
    color: $c-black;
    @extend %font-primary-medium;
}

%new-mobile-paragraph {
    font-size: 1.4rem;
    line-height: 171%;
    color: $c-black;
    @extend %font-primary-regular;
}

%new-small-paragraph {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $c-black;
    @extend %font-primary-regular;
}

%new-paragraph {
    font-size: 1.9rem;
    line-height: 157%;
    color: $c-dark-gray;
    @extend %font-primary-regular;
    @include media-breakpoint-down('sm') {
        font-size: 1.4rem;
    }
}
