@import 'src/global/variables';
@import 'src/global/typography';

.message {
    // display: flex;
    // flex-wrap: wrap;
    // align-items: end;
    margin-bottom: 2 * $mainsize;

    &__wrapper {
        display: flex;
        align-items: flex-end;
    }

    &__avatar {
        margin-right: $mainsize;
    }
    
    &__time{
        @extend %new-small-text;
        opacity: 0.6;
        text-align: center;
        margin-bottom: $mainsize;
    }
    
    &--outbound {
        // justify-content: flex-end;
        .message__wrapper {
            flex-direction: row-reverse;
        }
        .message__avatar {
            margin-left: $mainsize;
            margin-right: auto;
        }
    }
}

.message-text {
    border-radius: $mainsize;
    padding: $mainsize 2 * $mainsize;
    max-width: 47 * $mainsize;
    position: relative;
    cursor: pointer;
    
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: $mainsize;
    }

    &__time {
     
    }

    &__from {
        @extend %new-top-menu;
        margin-right: $mainsize;
    }

    &__text {
        @extend %new-h4;
        color: $c-black;
        font-size: 1.5rem;
        line-height: 126%;
    }

    &--inbound {
        background: #f4f3f1;
        &:after {
            content: '';
            position: absolute;
            left: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-right-color: #f4f3f1;
            border-left: 0;
            border-bottom: 0;
            margin-top: -8px;
        }
    }

    &--outbound {
        background: linear-gradient(180deg, lighten($c-green, 7%) 0%, rgba($c-green, 0) 100%), $c-green;
        // background: $c-orange;
        &:after {
            content: '';
            position: absolute;
            right: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-left-color: $c-green;
            border-right: 0;
            border-bottom: 0;
            margin-top: -8px;
        }
        .message-text__text,
        .message-text__time,
        .message-text__from {
            color: $c-white;
        }
    }
}

@include media-breakpoint-down('sm') {
    .message-text {
        max-width: 37 * $mainsize;
    }
}
