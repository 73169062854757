@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.experience-preview-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &__content-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__image {
    width: 64px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    margin-right: 8px;
  }

  &__title {
    @extend %new-small-text;
  }

  &__details {
    @extend %new-mobile-paragraph;
  }

  &__button {
    text-decoration: underline;
    flex:0 0 auto;
  }
}
