@import "src/global/grid";

.trip-types {
    &__title {
        margin-bottom: 24px;
    }

    &__cards-container {
        display: grid;
        gap: 24px;
        @include media-breakpoint-down("xs") {
            display: flex;
            flex-direction: column;
        }
    }

    // &__fishing-card {
    //     grid-column: 2;
    //     grid-row: 1 / span 2;
    // }

    // &__backcountry-card {
    //     grid-row: 1;
    //     grid-column: 1;
    // }

    // &__overnight-card {
    //     grid-column: 1;
    //     grid-row: 2 / span 2;
    // }

    // &__surfing-card {
    //     grid-column: 2;
    //     grid-row: 3;
    // }

    &__firsttimer-card {
        grid-column: span 2;
        grid-row: 1;
    }

    &__horseriding-card {
        grid-column: 1;
        grid-row: 2;
    }

    &__hunting-card {
        grid-column: 2;
        grid-row: 2;
    }

    &__skiing-card {
        grid-column: 1 / span 2;
        grid-row: 3;
    }
}
