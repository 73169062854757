@import "src/global/typography";

.conversation-header {
  display: flex;
  padding: $mainsize 0 $mainsize 3 * $mainsize;
  background: $c-white;
  z-index: 8;
  max-height: 7 * $mainsize;

  &__icon {
    opacity: 0.6;
    height: 1.5 * $mainsize;
    margin-right: 2 * $mainsize;
  }

  &__text-section {
    margin-left: $mainsize;
    display: flex;
    flex-direction: column;

    &--center {
      flex-direction: row;
      align-items: center;
    }
  }

  &__recipient {
    font-weight: 500;

    @extend %new-small-text;
  }

  &__response-rate {
    @extend %new-mobile-paragraph;

    &--number {
      color: $c-green;
      font-weight: 500;

      @extend %new-small-text;
    }
  }
}

@include media-breakpoint-down("sm") {
  .conversation-header {
    &__icon {
      display: none;
    }
  }
}
