@import 'src/global/variables.scss';
@import 'src/global/typography.scss';
// @import "src/global/grid.scss";

.form-group {
    &--columns {
        display: flex;
        > * {
            flex: 1 1 50%;
        }
        label + label {
            margin-top: 0 !important;
        }

        &.form-group--default {
            margin-left: -16px;
            margin-right: -16px;
            > * {
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        &.form-group--compact {
            margin-left: -4px;
            margin-right: -4px;
            > * {
                padding-left: 4px;
                padding-right: 4px;
            }
        }

        &.form-group--with-button {
            align-items: flex-end;
            > * {
                flex: 1 1 50%;
            }
        }
    }

    &__button{
        margin-bottom: 16px;
 
    }

    &--checkbox {
        align-items: center;
        min-height: 40px;
        > * {
            flex: 1 1 auto;
            & + * {
                flex: 1 1 100%;
            }
        }
    }
    &--vertical {
        align-items: center;
        min-height: 40px;
        > * {
            flex: 1 1 auto;
            & + * {
                flex: 1 1 100%;
            }
        }
    }

    @include media-breakpoint-down('md') {
        &--columns {
            &.form-group--default {
                margin-left: -8px;
                margin-right: -8px;
                > * {
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
            &.form-group--compact {
                margin-left: -4px;
                margin-right: -4px;
                > * {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }
    @include media-breakpoint-down('xs') {
        &--columns {
            flex-direction: column;
            > * {
                flex: 0 0 100%;
                width: 100%;
            }
        }

        &--vertical {
            flex-direction: row;
            > * {
                flex: 1 1 auto;
                & + * {
                    flex: 1 1 100%;
                }
            }
        }
    }
}
