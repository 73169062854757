@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.bs {
    &__block {
        padding: 20px 0;

        &:first-child {
            padding-top: 40px;
        }
    }

    &__button-return {
        margin-bottom: 32px;
    }

    h2 {
        margin-bottom: 16px;

        @extend %new-h3;
    }

    h3 {
        margin-bottom: 24px;

        @extend %new-h4;
    }

    &__cards-wrapper {
        display: grid;
        grid-gap: 2 * $mainsize;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;

        @include media-breakpoint-up('sm') {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-up('lg') {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
