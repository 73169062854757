@import "src/global/variables";
@import "src/global/grid";

.faq-item {
  &__title {
    margin-bottom: 24px;
    width: 100%;
    color: $c-white;
    border-bottom: 1px solid rgba($c-white, 0.4);
    button {
      width: 100%;
      font-size: 19px;
      line-height: 30px;
      text-align: left;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__content {
    overflow: hidden;
    color: $c-white;
    transition: height 0.3s ease-in-out;
  }

  &__inner {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 24px;
    transition: transform 0.3s ease-in-out;
  }
}
