@import "src/global/variables";
@import "src/global/typography";
@import "src/global/grid";

.section-wrapper {
  display: flex;
  flex-direction: column;
  &__title {
    @extend %new-h2;
    flex: 0 0 100%;
    span{
      @extend %new-top-menu;
      display: block;
    }
  }

  &__content {
    @extend %new-paragraph;
    margin-top: 30px;
    color: $c-black;
    flex: 0 0 100%;
  }

  &--small {
    .section-wrapper__title {
      @extend %new-top-menu;
    }
  }

  &--vertical {
    padding: 80px 106px !important;
    display: block;
    text-align: center;

    @include media-breakpoint-down("sm") {
      padding: 40px 16px !important;
    }

    h2 {
      margin-bottom: 16px;
    }
  }

  @include media-breakpoint-up("md") {
    flex-direction: row;
    &__title {
      flex: 0 0 30%;
      margin-right: 3 * $mainsize;
    }

    &__content {
      flex: 1 1 100%;
      margin-top: 5px;
    }
    &--small {
      .section-wrapper__title {
        flex: 0 0 120px;
        margin-top: 0.5 * $mainsize;
      }
    }
  }
}
