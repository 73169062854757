@import 'src/global/variables.scss';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.location-edit {
    h5 {
        margin-bottom: $mainsize;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &--error {
        .location-edit__map {
            border: 1px solid $c-red;
        }
    }

    &__error {
        margin-top: $mainsize;
        @extend %new-top-menu;
        color: $c-red;
        font-size: 1.4rem;
        line-height: 114%;
        margin-left: 2 * $mainsize;
    }

    &__ref-input {
        opacity: 0;
        height: 0;
        display: block;
    }
}
