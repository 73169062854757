@import 'src/global/variables.scss';
@import 'src/global/grid';

.account-details-container {
    &__wrapper {
        display: flex;
        justify-content: center;
        margin-top: 42px;
    }
}
