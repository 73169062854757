@import 'src/global/variables';
@import 'src/global/typography';

.container {
    width: 100%;
    height: auto;

    background-color: $color-white;
    border: thin solid transparentize($color-black, 0.9);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list {
    flex: 1;
    margin: 24px 0 12px;
    overflow: auto;
    li {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
        p {
            flex: 1;
            opacity: 0.8;
        }
        span {
            font-weight: 500;
        }
        p,
        span {
            font-size: 1.7rem;
            line-height: 1.4;
        }
        span {
            margin-left: 8px;
        }
        &.variant-heading {
            margin-bottom: 24px;
            p {
                font-weight: 700;
            }
        }
        &.variant-subheading {
            margin-top: 24px;
            p {
                font-weight: 500;
                opacity: 1;
            }
        }
    }
}

.container footer {
    display: flex;
    flex-direction: column;
   

    button {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

@include media-breakpoint-down('sm') {
    .container {
        padding: 2 * $mainsize;
    }
}
