@import "src/global/variables";
@import "src/global/grid";

.what-provide {
  padding-top: 48px;
  padding-bottom: 48px;

  @include media-breakpoint-up("sm") {
    padding-top: 136px;
    padding-bottom: 164px;
  }

  &__header {
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 64px;
    }
  }

  &__cards {
    @include media-breakpoint-up("md") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: 16px;

    @include media-breakpoint-up("md") {
      margin-bottom: 0;
    }

    &_3 {
      @include media-breakpoint-up("md") {
        width: calc((100% - 2 * 26px) / 3);
      }
    }

    &_4 {
      @include media-breakpoint-up("md") {
        width: calc((100% - 2 * 26px) / 4);
      }
    }
  }
}
