@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.blog-card-featured {
  background-color: $c-base;
  padding: 16px;
  border-radius: $mainsize;

  @include media-breakpoint-up("sm") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
  }

  &__image-wrapper,
  &__content {
    @include media-breakpoint-up("sm") {
      max-width: calc((100% / 2) - (56px / 2));
      width: 100%;
    }
  }

  &__image-wrapper {
    width: 100%;

    @include media-breakpoint-down("sm") {
      margin-bottom: 24px;
    }
  }

  &__image {
    height: 368px;

    @include media-breakpoint-down("sm") {
      height: 232px;
    }
  }

  &__date {
    display: block;
    opacity: 0.7;
    margin-bottom: 16px;

    @extend %new-small-paragraph;
  }

  &__title {
    @extend %new-h3;

    margin-bottom: 16px;
  }

  &__description {
    @extend %new-small-paragraph;

    margin-bottom: 32px;
  }

  &__link {
    min-width:120px;
    @include media-breakpoint-down("sm") {
      width: 100%;
    }
  }
}
