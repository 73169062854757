@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.messages-button {
    width: 48px;
    height: 48px;
    position: relative;
    sup {
        @extend %new-extra-small-text;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: $mainsize;
        right: calc(#{$mainsize} / 2);
        border-radius: $mainsize;
        background: $c-dark-gray;
        color: $c-white;
        width: 2 * $mainsize;
        height: 2 * $mainsize;
    }

    &--prominent {
        sup {
            background: $c-red;
        }
    }
}
