@import 'src/global/variables';
@import 'src/global/typography';
@import 'src/global/grid';

.customList {
    margin-bottom: -2 * $mainsize;
    & > li {
        margin-bottom: 2 * $mainsize;
        padding-left: 4 * $mainsize;

        &:before{
          display: inline-block;
          content: '';
          margin-left: -4 * $mainsize;
          margin-right: 1 * $mainsize;
          width: 3 * $mainsize;;
          height: 3 * $mainsize;;
          vertical-align: middle;
          background-image: url("../../../assets/icons/icon_bullet_arrow.svg");
        }
    }
}
