@import 'src/global/variables';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.host-info {
    margin-bottom: 80px;

    @include media-breakpoint-up('md') {
        margin-bottom: 138px;
    }

    &__details {
        width: 100%;
        text-align: center;

        @include media-breakpoint-up('md') {
            // max-width: 671px;
            max-width: 800px;
            text-align: left;
        }
    }

    &__address {
        @extend %new-h4;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
        margin-bottom: 16px;

        @include media-breakpoint-up('md') {
            justify-content: initial;
        }
    }

    &__address-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 8px;
    }

    &__name {
        @extend %new-h1-sans;

        margin-bottom: 32px;
    }

    &__description {
        @extend %new-h3;
    }
}
