@import "src/global/variables";
@import "src/global/typography";
@import "src/global/grid.scss";

.hero {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: $ff-default;
    z-index: 1;
    position: relative;
    @include media-breakpoint-up("md") {
      min-height: 680px;
    }
    @include media-breakpoint-only("sm") {
      min-height: 650px;
    }
  }
  &__overlay {
    background: linear-gradient(
      90deg,
      rgba($color-black-full, 0.2) 0%,
      rgba($color-black-full, 0) 100%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    img {
      border-radius: 0;
    }
  }

  &__title {
    @extend %font-secondary-extra-bold;
    margin-top: auto;
    color: $color-white;
    font-size: 5rem;
    line-height: 6rem;
    margin-bottom: 18px;
    @include media-breakpoint-up("md") {
      margin-top: auto;
    }
    @include media-breakpoint-up("sm") {
      font-size: 6.2rem;
      line-height: 6.5rem;
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    color: $color-white;
    font-size: 1.8rem;
    line-height: 3rem;
    font-family: $ff-default;
    font-weight: 700;
    margin-bottom: 8px;

    @include media-breakpoint-up("sm") {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  &__details {
    font-family: $ff-default;
    color: $color-white;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 10px;

    @include media-breakpoint-only("xs") {
      margin-bottom: 220px;
    }

    @include media-breakpoint-up("md") {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: initial;
    }
  }

  &__cta-button {
    margin-top: auto;
    margin-bottom: 24px;
    width: 100%;

    @include media-breakpoint-up("sm") {
      margin-top: 48px;
      max-width: 200px;
      margin-bottom: auto;
      @media screen and (max-height: 700px) {
        margin-bottom: 80px;
      }
    }
  }
}

.navbar-banner {
  padding-top: 16px;
  padding-bottom: 60px;

  &__content {
    @extend %font-primary-regular;
    font-size: 1.4rem;
    padding: 8px;
    width: 100%;
    background: linear-gradient(315deg, lighten($c-green, 7%) 0%, $c-green 50%),
      $c-green;
    border-radius: 8px;
    color: $c-white;

    @include media-breakpoint-up("sm") {
      padding: 12px;
    }
    a {
      font-weight: 600;
    }
  }

  @include media-breakpoint-down("sm") {
    padding-top: 80px;
    padding-bottom: 0;
  }
}
