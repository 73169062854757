@import '../../variables';
@import '../../typography';

.Toastify{
    &__toast-body{
        @extend %font-primary-medium;
        font-size: 1.7rem;
        color: $c-black;
    }
    &__toast-container{
        max-width:500px;
        width: auto !important;
    }
    &__progress-bar{
        background: $c-red !important;
    }
}