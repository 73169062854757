@import '../../variables';
@import '../../typography';

$datepicker__horizontal-margin: 6.5rem;
$datepicker__mobile-horizontal-margin: 1.5rem;

$datepicker__background-color: transparent;
$datepicker__border-color: transparent;
$datepicker__highlighted-color: transparent;
$datepicker__muted-color: $c-light-gray;
$datepicker__selected-color: transparent;
$datepicker__text-color: #1b1b1b;
$datepicker__header-color: #000;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 4rem;
$datepicker__day-margin: 0.8rem;
$datepicker__mobile-day-margin: 0.53rem;
$datepicker__font-size: 1.7rem;
$datepicker__font-family: $ff-default;
$datepicker__item-size: 4rem;
$datepicker__mobile-item-size: 3rem;
$datepicker__margin: 1rem $datepicker__horizontal-margin;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;

@import '../../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
    border: 1px solid $c-light-gray;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    margin: 0;

    @extend %font-primary-medium;
    &__day {
        color: $c-gray;
        width: $datepicker__item-size;
        height: $datepicker__item-size;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        &--highlighted,
        &--selected {
            border-width: 2px;
            border-style: solid;
            color: $c-black;
        }
        &--highlighted {
            border-color: $c-light-gray;
        }

        &--outside-month {
            color: $c-semi-light-gray;
            border-color: $c-semi-light-gray;
        }

        &--disabled {
            color: $c-light-gray;
            border: none;
        }

        &--today {
            color: $c-black;
        }

        &--selected,
        &--range-end {
            position: relative;
            border-color: $c-white;
            background-color: $c-black;
            color: $c-white;
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                color: #1b1b1b;
                width: calc(100% + 0.8rem);
                height: calc(100% + 0.8rem);
                border: 2px solid;
                border-radius: calc(100% + 0.8rem);
            }
            &:hover {
                background-color: $c-very-dark-gray;
            }
        }

        &--in-range {
            border: 2px solid $color-grey2;
            border-radius: 100px;
        }

        &--in-selecting-range:not(&--selected) {
            border: 2px solid $color-grey2;
            border-radius: 100px;
            background: white;
        }
    }

    &__current-month {
        display: flex;
        justify-content: center;
        margin-left: calc(#{$datepicker__horizontal-margin} + #{$datepicker__day-margin} + 1rem);
        margin-bottom: $datepicker__item-size;
    }

    // &__header{
    //     @extend %new-h3;
    // }
    &__current-month {
        .react-datepicker & {
            @extend %new-h3;
        }
    }

    @include media-breakpoint-down('md') {
        &__current-month {
            margin-left: calc(#{$datepicker__mobile-horizontal-margin} + #{$datepicker__day-margin} + 1rem);
            margin-bottom: $datepicker__mobile-item-size;
        }
        &__month {
            margin-left: $datepicker__mobile-horizontal-margin;
            margin-right: $datepicker__mobile-horizontal-margin;
        }
        &__navigation {
            &--previous {
                left: -40px;
            }
            &--next {
                right: -40px;
            }
        }
    }

    @include media-breakpoint-down('xs') {
        border: none;
        &__current-month {
            margin-left: calc(#{$datepicker__mobile-horizontal-margin} + #{$datepicker__mobile-day-margin});
            margin-bottom: 1rem;
        }
        &__day,
        &__day-name {
            width: $datepicker__mobile-item-size;
            height: $datepicker__mobile-item-size;
            margin: $datepicker__mobile-day-margin;
        }
        &__month {
            margin-left: 0;
            margin-right: 0;
        }
        &__navigation {
            top: 180px;
            width: 20px;
            &--previous {
                left: -15px;
            }
            &--next {
                right: -15px;
            }
        }
    }
}
