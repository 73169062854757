@import "src/global/variables";
@import "src/global/grid";

.reviews {
  &__header {
    margin-bottom: 20px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 24px;
    }
  }

  &__cards {
    @include media-breakpoint-up("sm") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__single-review {
    width: 100%;
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      width: calc((100% - 2 * 24px) / 3);
      margin-bottom: 0;
    }
  }
}
