@import 'src/global/variables';
@import 'src/global/grid';

.icons-section {
    margin-bottom: -3 * $mainsize;
    &__icon{
        margin-bottom: 3 * $mainsize;
    }
    @include media-breakpoint-up('md') {
        display: flex;
        flex-wrap: wrap;
        > .icons-section__icon {
            flex: 0 0 50%;
            padding-right: 2 * $mainsize;
            &:last-of-type:first-of-type {
                flex: 0 0 100%;
            }
        }
    }
}
