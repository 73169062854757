@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.are-you-a-guide {
  &__image {
    min-height: 300px;
    max-height: 600px;
  }

  &__overlay-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: 520px;
    border-radius: 8px;

    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0.21%, rgba(255, 255, 255, 0) 99.79%),
      linear-gradient(180deg, rgba(188, 105, 81, 0.14) 0%, rgba(188, 105, 81, 0) 100%),
      url("../../../../assets/img/guide_are_you.jpg") center;
    background-size: cover;

    @include media-breakpoint-down("sm") {
      padding: 16px;
    }
  }

  &__button {
    display: inline-flex;
    @include media-breakpoint-up("sm") {
      max-width: 200px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 111%;
    color: $color-white;
    width: max-content;
  }

  &__sub-title {
    @extend %new-h3;
    color: $color-white;
    font-size: 24px;
    flex-grow: 1;
  }
}
