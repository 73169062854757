@import 'src/global/variables';
@import 'src/global/typography.scss';
@import 'src/global/styles';

.container {
    width: 100%;
    border: thin solid transparentize($color-black, 0.9);
    border-radius: 8px;
    padding: 32px;
    h3,h5 {
        margin-bottom: 24px;
    }
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
        width: 100%;
    }
    :global(.input-half) {
        width: calc(50% - 8px);
    }
}

.switch{
    margin-bottom: 4 * $mainsize;
}

@include media-breakpoint-down('sm') {
    .container {
        padding: 2 * $mainsize;
    }
}
