/* colors
 main colors */
$color-primary: #a53820;
$color-interface: #be7f70;
$color-orange: #d25034;
$color-error: #d25034;
$color-black: #1b1b1b;
$color-black-full: #000;
$color-white: #fff;
$color-light: #f9f3f1;
$color-bg: #2f333c;
$color-bg-10: rgba(47, 51, 59, 0.1);
$color-bg-20: rgba(47, 51, 59, 0.2);
$color-bg2: #393d47;
$color-bg3: #444852;
$color-bg4: #fbfaf9;

$color-dark: #606b76;
$color-green: #086b61;

/* grey */
$color-grey0: #f4f3f1;
$color-grey1: #c5c5c5;
$color-grey2: #828282;
$color-grey3: #4f4f4f;
$color-grey4: #333;

/* REDESIGN */
$c-gray: #767676;

$c-dark-gray: #5f5f5f; // #1b1b1b with 70% opacity
$c-very-dark-gray: $color-bg;
$c-light-gray: #ccc; // used also for 70% #fff
$c-light-gray-3: #9f9f9f;
$c-semi-light-gray: #e6e6e6;
$c-very-light-gray: #fafafa;

$c-black: #1b1b1b;
$c-red: #bb3637;
$c-orange: $color-orange;
$c-dark-orange: $color-primary;
$c-light-smoke: #fafafa;
$c-green: #086b61;
$c-white: $color-white;
$c-light-gray-2: #f6f6f5;
$c-gray-0: $color-grey0;
$c-base: $color-light;
$c-silver: linear-gradient(85.95deg, rgba(245, 243, 242, 0.91) 0%, rgba(246, 246, 245, 0.71) 100%);
$c-blue: #007aff;
$c-electric-brown: #BC6951;

/* fonts */
$weight-regular: 400;
$weight-medium: 500;
$weight-demi: 600;
$weight-bold: 700;

/* global variables */
$mainsize: 8px;
$mainLetterSpacing: 0.08em;
$buttonFontSize: 12px;
$mainTransition: cubic-bezier(0.465, 0.183, 0.153, 0.946);
$dialog-z-index: 10001;
$time-picker-max-width: 112px;
$grid-width: 1440px;
$grid-padding-mobile: $mainsize * 2;
$grid-padding-desktop: $mainsize * 13;
$grid-padding-sm: $mainsize * 3;
$grid-padding-sm-thick: $mainsize * 10;
$grid-padding-sm-extra-thick: $mainsize * 16;
$grid-padding-md: $mainsize * 7.5;
$grid-padding-md-thick: $mainsize * 14;
$grid-padding-md-extra-thick: $mainsize * 20;

/* borders */
$border-light: 1px solid rgba(0, 0, 0, 0.1);
$border-medium: 2px solid rgba(0, 0, 0, 0.1);
$border-thick: 4px solid rgba(0, 0, 0, 0.1);

// transition types
$cubic: cubic-bezier(0.17, 0.67, 0.83, 0.67);
$ease: ease-in-out;
$linear: linear;

//Header and Footer variables
$header-height: 118px;
$header-height-mobile: 80px;
$header-sized-height: 106px;
$footer-height: 104px;
$sticky-footer-height: 104px;
$search-height: 110px;
$floating-component-height: 104px;
$footer-height-creation-event-md: 160px;

//mixins
$borderRadius: 40px;
$defaultInputHeight: 50px;

// z-indexes
$floating-component-z-index: 2;
$dropdown-button-z-index: 4;
$dropdown-content-z-index: 3;

// fonts
$ff-default: 'National 2', sans-serif;
$ff-rokkitt: 'Rokkitt', serif;
