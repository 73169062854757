@import "src/global/variables";
@import "src/global/typography";

.button {
  @extend %new-top-menu;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 16px 24px;
  transition: background-color 0.2s $ease, color 0.2s $ease, border 0.2s $ease,
  opacity 0.2s $ease;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &--primary {
    background-color: $color-bg;
    color: $color-white;

    &.button--hover {
      color: $color-white;
    }
  }

  &--secondary {
    background-color: $color-primary;
    color: $color-white;

    &.button--hover {
      background-color: $color-bg;
    }
  }

  &--tertiary {
    background: linear-gradient(180deg, lighten($c-green, 7%) 0%, $c-green 50%), $c-green;
    color: $color-white;

    &.button--hover {
      background: $c-green;
    }
  }

  &--light {
    background-color: $color-white;
    color: $c-black;

    &.button--hover {
      background-color: $color-white;
      color: $c-dark-gray;
    }
  }

  &--link {
    color: $color-grey3;
    font-size: 2rem;
    line-height: 2.6rem;
    padding: 0;
    border-bottom: 1px solid $color-grey3;
    border-radius: 0 !important;
  }

  &--link-small {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: $weight-regular;
    padding: 0;
    border-bottom: 1px solid $color-grey3;
    border-radius: 0 !important;
  }

  &--clean {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    padding: 0;
    border: none;
    border-radius: 0 !important;

    .button__icon {
    }
  }

  &--plain-orange {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: $c-orange;
    padding: 14px 0;

    &-secondary {
      border-color: $c-orange;
      color: $c-orange;
    }
  }


  &--plain {
    border-color: $c-gray;
    background-color: transparent;
    color: $c-black;

    &-no-border {
      border: none;
    }

    &.button--hover {
      background-color: $c-very-light-gray;
    }

    &.button--secondary {
      border-color: $color-primary;
      background-color: transparent;
      color: $color-primary;

      &.button--hover {
        color: $color-white;
        border-color: $color-bg;
        background-color: $color-bg;
      }
    }
  }

  &--orange {
    flex-shrink: 0;
    background-color: $c-orange;
    padding: 12px 0;
    color: $color-white;
  }

  &--transparent {
    background-color: transparent;
    color: $color-grey4;
  }


  &--normal {
  }

  &--tab {
    font-size: 1.7rem;
    line-height: 2.4rem;
    padding: 8px;
    text-align: center;
    opacity: 0.8;
    border: none;
    color: $c-black;
    border-bottom: 2px solid rgba($c-black, 0.2);
    transition: color 0.2s $ease, border-bottom-color 0.2s $ease;

    &.button--active {
      color: $c-dark-orange;
      border-bottom-color: $c-dark-orange;
    }
  }

  &--outline {
    border: thin solid $color-bg;
    border-radius: 8px;
    padding: 11px 20px;
  }

  &--back {
    color: $c-dark-orange;
    opacity: 0.7;
  }

  &--small {
    padding: 11px 20px;
  }

  &--extra-small {
    padding: 8px 20px;
  }

  &--inline {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }

  &--wide {
    min-width: 280px;
  }

  &--full {
    min-width: 280px;
    width: 100%;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
    transition: none;
  }

  &--radius-none {
    border-radius: 0;
  }

  &--radius-small {
    border-radius: 8px;
  }

  &--radius-full {
    border-radius: 100px;
  }

  &--loading {
    pointer-events: none;
    transition: none;
    border-color: rgba(255, 255, 255, 0.7);

    &::after {
      position: absolute;
      content: "";
      background-color: rgba(255, 255, 255, 0.7);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &--text-medium{
    @extend %font-primary-medium;

    font-size: 1.5rem !important;
    line-height: 107% !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &--text-small {
    @extend %font-primary-medium;

    font-size: 1.4rem !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    white-space: nowrap;

    label {
      font-weight: 500 !important;
    }

    .button__icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__loader {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }


  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
  }


  @include media-breakpoint-down("sm") {
    &--wide {
      min-width: auto;
      width: 100%;
    }
  }
}
