.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    flex: 0 0;
    svg {
        max-width: none;
    }
}

.iconAsButton {
    cursor: pointer;
}
