@import 'src/global/variables';
@import 'src/global/typography';

.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &__header {
        @extend %new-h3;
        padding: 24px;
        text-align: center;
        button {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            cursor: pointer;

            svg {
                color: $c-black;
            }
        }
    }
    &__loader {
        margin-top: 30px;
    }
    &__container {
        position: relative;
        background-color: $c-white;
        overflow: auto;
        max-height: 100%;
        border-radius: 8px;
        &:focus {
            outline: none;
        }
    }

    &__content {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 30px 30px;
    }

    &__close-button {
        position: absolute;
        top: 30px;
        right: 24px;
        z-index: 1;

        cursor: pointer;

        svg {
            width: 14px;
            height: 14px;
            color: $c-black;
        }
    }

    &--full-screen {
        .custom-modal__container {
            border-radius: 0;
            width: 100vw;
            height: 100vh;
            max-width: none;
        }

        .custom-modal__content {
            max-width: 800px;
            padding: 70px 30px;
        }

        .custom-modal__close-button {
            position: fixed;
        }
    }
}
