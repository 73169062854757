@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.our-story {
  &__header {
    @extend %font-secondary-bold;
    color: $c-dark-orange;
    margin-bottom: 24px;
    font-size: 2.7rem;
    line-height: 3.2rem;

    @include media-breakpoint-up("sm") {
      margin-bottom: 40px;
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
  }

  &__content {
    background-color: $c-dark-orange;
    padding: 24px 24px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up("md") {
      padding: 120px 128px;
      flex-direction: row;
    }
  }

  &__logo {
    margin-bottom: 22px;
    width: clamp(208px, 100%, 208px);

    @include media-breakpoint-up("md") {
      margin-bottom: 0;
      margin-right: 88px;
      width: clamp(208px, 30%, 324px);
    }
  }

  &__description {
    color: $c-white;
    font-size: 1.9rem;
    line-height: 3rem;

    @include media-breakpoint-up("sm") {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
}
