@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.experience-host {
    display: flex;
    align-items: center;

    &__image {
        flex: 0 0 225px;
        height: 225px;
        margin-right: 6 * $mainsize;
    }

    &__details {
        flex: 1 1 50%;
        margin-right: 8 * $mainsize;

        & > * {
            margin-bottom: 3 * $mainsize;
        }
    }

    &__description {
        @extend %new-paragraph;
        flex: 0 0 45%;
    }

    &__title {
        @extend %new-h2;
    }

    &__name {
        @extend %new-h3;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        max-width: 230px;
    }

    > .experience-host__button {
        display: none;
    }

    @include media-breakpoint-down('md') {
        flex-wrap: wrap;

        &__description {
            flex: 0 0 100%;
        }

        &__image {
            margin-bottom: 3 * $mainsize;
        }

        &__details {
            margin-right: 0;
        }
    }

    @include media-breakpoint-down('sm') {
        &__image {
            flex-basis: 120px;
            height: 120px;
            margin-right: 2 * $mainsize;
            margin-bottom: 2 * $mainsize;
        }

        &__details {
            .experience-host__button {
                display: none;
            }

            & > * {
                margin-bottom: 1 * $mainsize;
            }
        }

        &__description {
            margin-bottom: 2 * $mainsize;
        }

        > .experience-host__button {
            display: block;
            margin-bottom: $mainsize;
        }
    }
}
