@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.rbc-calendar {
  width: 100%;
  background-color: $c-white;
  border-radius: 8px;
  border: 1px solid rgba($c-black, 0.1);

  .rbc-month-view {
    border: none;
    border-top: 1px solid rgba($c-black, 0.1);
  }

  button {
    color: $c-black !important;
  }

  .rbc-btn-group {
    button {
      padding: 8px 20px;
    }
  }

  .rbc-toolbar {
    flex-direction: column;
    padding: 16px 16px;
    margin: 0;

    @include media-breakpoint-up("md") {
      flex-direction: row;
    }

    button {
      border-radius: 8px;
    }
  }

  .rbc-toolbar-label {
    @extend %new-h4;
    @extend %font-primary-medium;

    color: $c-black;
    margin-top: 16px;
    text-align: right;

    @include media-breakpoint-up("md") {
      margin: 0;
    }
  }

  .rbc-header {
    span {
      @extend %font-primary-medium;

      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .rbc-today {
    background: rgba($color-green, 0.1);
  }

  .rbc-event {
    background-color: $color-green;
    border: 1px solid rgba($c-green, 0.5);
    @extend %font-primary-medium;

    &--pending {
      background: rgba($color-green, 0.1);
      color: $c-black;
    }
  }

  .rbc-day-bg {
    cursor: pointer;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border: 1px solid rgba($c-green, 0.5);
    }

    &--selected {
      border: 1px solid $c-green;
    }

    &--inactive {
      background: repeating-linear-gradient(-45deg, $c-white, $c-white 5px, #e6e6e6 5px, #e6e6e6 10px);
    }
  }
  .rbc-row-content{
    pointer-events: none;
    .rbc-event{
      pointer-events: auto;
    }
  }
}