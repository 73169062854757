@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.blog-card {
  display: block;

  &__image {
    width: 100%;
    height: 200px;
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 24px;
    }
  }

  &__title {
    @extend %new-h3;

    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 8px;
    }
  }

  &__description {
    @extend %new-small-paragraph;
  }
}
