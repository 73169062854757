.home-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;

  &__logo {
    height: 31px;
    width: 138px;
    flex:0 0 138px;
    display: block;
  }

  &__logo-small {
    height: 31px;
    width: 31px;
    flex:0 0 31px;
    display: block;
  }
}
