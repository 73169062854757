.edit-trips {
    &__wrapper {
        margin-top: 40px;
        margin-bottom: 106px;
    }
    &__trips-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 24px;
        max-width: 896px;
        width: 100%;
    }
}