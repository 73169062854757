@import 'src/global/variables.scss';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.included-icon {
    @extend %new-paragraph;
    display: flex;
    align-items: center;

    &__title {
        color: $c-black;
        margin-right: 0.5 * $mainsize;
    }

    &__description {
        color: $color-grey2;
    }
    
    &__image {
        margin-right: 3 * $mainsize;
        min-width: 5 * $mainsize;
        img {
            width: 5 * $mainsize;
            height: 5 * $mainsize;
            max-width: none;
        }
    }
}
