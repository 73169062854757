@import "src/global/variables";
@import "src/global/typography";
@import "src/global/grid";

.experience-header {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2 * $mainsize;
  }
  &__title {
    @extend %new-h1;
  }

  &__address {
    @extend %new-h4;
  }

  &__price {
    @extend %new-h3;
    align-self: flex-end;
    white-space: nowrap;
    margin-left: 2 * $mainsize;
  }
  &__gallery {
    margin-bottom: 2 * $mainsize;
    @include media-breakpoint-down("sm") {
      width: 100vw;
      margin-left: -24px;

      img {
        border-radius: 0;
      }
    }
  }
  &__description {
    @extend %new-paragraph;
  }
  &__details {
    @extend %new-small-text;
    color: $c-light-gray-3;
    span {
      display: inline-block;
      margin-top: 3px;
      & + span {
        &:before {
          content: "·";
          display: inline-block;
          margin: 3px 3px 0;
        }
      }
    }
  }

  @include media-breakpoint-down("sm") {
    &__top {
      flex-direction: column;
      align-items: flex-start;
    }
    &__title {
      margin-bottom: 1 * $mainsize;
    }
    &__price {
      color: $c-dark-orange;
      margin-left: 0;
      align-self: flex-start;
    }
  }
}
