@import "src/global/variables";

.conversation-protection-message {
  background: $color-light;
  display: flex;
  align-items: center;
  padding: 2 * $mainsize 2* $mainsize 2* $mainsize 3 * $mainsize;
  position: sticky;
  z-index: 8;
  // The same as the size of conversation header
  top: 7 * $mainsize;

  &__close {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &__lock {
    width: 3 * $mainsize;
    height: 3 * $mainsize;
    border: 1px solid $c-black;
    border-radius: 100%;
    display: flex;
    justify-content: center;

    &--icon {
      width: 1.5 * $mainsize;
      height: 1.5 * $mainsize;
    }
  }

  &__text {
    margin: 0 $mainsize;
    flex: 1;
  }
}
