@import "src/global/typography";

.conversation-list {
  padding: 2 * $mainsize 2 * $mainsize 0 0;
  overflow-y: auto;
  height: 100%;

  &__header {
    margin: 0 0 2 * $mainsize $mainsize;

    @extend %new-top-menu;
  }

  &__item {
    margin-bottom: $mainsize;
  }
}

@include media-breakpoint-down("sm") {
  .conversation-list {
    padding-left: 2 * $mainsize;

    &__header {
      margin-left: 0;
    }
  }
}
