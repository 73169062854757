.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &--full-screen {
        width: 100vw;
    }
    &--fill-height {
        height: 100%;
    }
    &--overlay {
        position: absolute;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: rgba(255, 255, 255, 0.5);
    }
}
