@import "global/reset";
@import "global/variables";
@import "global/grid";
@import "global/spacing";
@import "global/styles";
@import "global/global-utils";

@import "global//vendor/toastify/toastify.scss";
@import "global/vendor/react-datepicker/react-datepicker.scss";
@import "global/vendor/mapbox/mapbox-gl.css";
@import "global/vendor/mapbox/mapbox-custom-styles.scss";

@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import "global/vendor/react-big-calendar/react-big-calendar-custom";

html {
  font-size: 62.5%;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $c-black;
  font-family: $ff-default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $weight-medium;
}
h1 {
  font-size: 3rem;
  line-height: 4.2rem;

  @include media-breakpoint-up("sm") {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}

h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;

  @include media-breakpoint-up("sm") {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 1.333;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none {
      display: none !important;
    }

    .d#{$infix}-inline {
      display: inline !important;
    }

    .d#{$infix}-inline-block {
      display: inline-block !important;
    }

    .d#{$infix}-block {
      display: block !important;
    }

    .d#{$infix}-table {
      display: table !important;
    }

    .d#{$infix}-table-row {
      display: table-row !important;
    }

    .d#{$infix}-table-cell {
      display: table-cell !important;
    }

    .d#{$infix}-flex {
      display: flex !important;
    }

    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
}

//
// Utilities for toggling `display` in print
//

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row {
      flex-direction: row !important;
    }

    .flex#{$infix}-column {
      flex-direction: column !important;
    }

    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }

    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }

    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }

    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }

    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }

    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }

    .justify-content#{$infix}-center {
      justify-content: center !important;
    }

    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }

    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }

    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }

    .align-items#{$infix}-center {
      align-items: center !important;
    }

    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }

    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }

    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }

    .align-content#{$infix}-center {
      align-content: center !important;
    }

    .align-content#{$infix}-between {
      align-content: space-between !important;
    }

    .align-content#{$infix}-around {
      align-content: space-around !important;
    }

    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }

    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }

    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }

    .align-self#{$infix}-center {
      align-self: center !important;
    }

    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }

    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}

::placeholder,
::-webkit-input-placeholder, /* Chrome/Opera/Safari */
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-black;
  opacity: 0.2 !important;
  line-height: inherit !important;
}

.intercom-lightweight-app,
.intercom-lightweight-app-launcher,
.intercom-launcher-frame {
  @include media-breakpoint-only("xs") {
    bottom: 90px !important;
  }
  z-index: 10 !important;
}
.intercom-messenger-frame {
  @include media-breakpoint-only("xs") {
    bottom: 160px !important;
  }
  z-index: 10 !important;
}

.intercom-launcher-badge-frame {
  @include media-breakpoint-only("xs") {
    bottom: 132px !important;
  }
  z-index: 10 !important;
}
