@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.side-menu {
    &__content {
        display: flex;
        flex-direction: column;
        width: 360px;
        max-width: 90vw;
        min-height: 100vh;
        padding: 30px 24px 32px;
        overflow-y: auto;
    }
    &__close {
        margin-left: auto !important;
    }
    &__trigger {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__logo {
        margin-right: 80px;
    }

    &__menu {
        margin-bottom: 40px;
    }

    &__separator {
        margin: 24px -24px;
        width: calc(100% + 48px);
    }

    &__link {
        display: block;
        font-size: 1.7rem;
        line-height: 2.4rem;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
        position: relative;
        &--primary {
            font-weight: $weight-medium;
        }

        &--secondary {
            opacity: 0.7;
        }

        &--underline {
            text-decoration: underline;
        }
    }
    &__text {
        font-size: 1.7rem;
        line-height: 2.4rem;
        display: block;
    }
    &__support {
        margin-top: auto;
        margin-bottom: 40px;
    }

    &__privacy {
        margin-bottom: 25px;
    }

    &__copyright {
        opacity: 0.7;
    }

    &__year {
        font-weight: $weight-medium;
    }
}
