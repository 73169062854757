@import 'src/global/variables.scss';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.photos-upload {
    margin-bottom: 2 * $mainsize;
    &__dropdown-area {
        min-height: 142px;
        border: 1px dashed $c-light-gray;
        border-radius: $mainsize;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            p {
                span {
                    color: $c-orange;
                }
            }
        }
        p {
            @extend %new-top-menu;
            font-weight: 400;
            text-align: center;
            color: $c-dark-gray;
            span {
                color: $c-black;
                display: block;
                font-weight: 500;
            }
        }

        &--accept {
            border: 1px dashed $c-dark-gray;
            p {
                color: $c-black;
            }
        }
        &--reject {
            border: 1px dashed $c-orange;
            p {
                color: $c-orange;
                span {
                    color: $c-orange;
                }
            }
        }
        &--error {
            border: 1px dashed $c-red;
            p {
                color: $c-red;
                span {
                    color: $c-red;
                }
            }
        }
    }
    &__error {
        color: $c-red;
        span {
            color: $c-red;
        }
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__images-area {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100%;
        margin: 0 -0.5 * $mainsize;
        padding: 2 * $mainsize 0 0;

        & > * {
            width: 140px;
            flex: 0 0 140px;
            padding: 0 0.5 * $mainsize;
            user-select: none;
            img {
                pointer-events: none;
            }
        }
        :global .placeholder {
            opacity: 0.1;
        }
        :global .dragged {
            z-index: 10;
            opacity: 0.7;
        }
    }
    &__ref-input {
        opacity: 0;
        height: 0;
    }
    &__image-overlay {
        position: absolute;
        z-index: 2;
        top: -8px;
        right: 15px;
        background: $c-black;
        color: $c-white;
        font-size: 1rem;
        line-height: 1rem;
        padding: 4px;
        border-radius: 16px;
        &--delete {
            left: auto;
            right: -8px;
            width: 16px;
            height: 16px;
            padding: 5px;
            border-radius: 16px;
            cursor: pointer;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
