@import 'src/global/variables';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.hero-host {
    margin-bottom: 80px;

    @include media-breakpoint-up('md') {
        margin-bottom: 138px;
    }

    &__bg {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 120px;

        @include media-breakpoint-up('md') {
            height: 200px;
        }
    }

    &__avatar-wrapper {
        position: relative;
        width: 100%;
    }

    &__avatar {
        position: absolute;
        top: 0;
        left: 50%;
        width: 150px;
        height: 150px;
        transform: translate(-50%, -50%);
        filter: drop-shadow(0 10px 100px rgba(97, 63, 53, 0.1));

        @include media-breakpoint-up('md') {
            left: 0;
            transform: translate(0, -50%);
            width: 220px;
            height: 220px;
        }
    }

    &__badge {
        max-width: 60px;
        max-height: 60px;

        &:not(:last-child) {
            margin-right: 32px;
        }

        @include media-breakpoint-up('sm') {
            max-width: 112px;
            max-height: 112px;
        }
    }
}
