@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.footer {
  &__upper,
  &__lower {
    display: flex;
  }

  &__upper {
    color: $c-white;
    padding-top: 48px;
    padding-bottom: 24px;

    @media all and (max-width: 600px) {
      justify-content: space-between;
    }

    @include media-breakpoint-up("sm") {
      padding-top: 40px;
      padding-bottom: 24px;
    } 
  }

  &__lower {
    color: rgba($c-white, 0.6);
    border-top: 1px solid rgba($c-white, 0.15);
    padding-top: 10px;
    padding-bottom: 48px;
    justify-content: flex-end;

    @include media-breakpoint-up("sm") {
      padding-top: 10px;
      padding-bottom: 24px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 102px;

      @media all and (max-width: 600px) {
        margin-right: 0;
      }

      @include media-breakpoint-up("sm") {
        margin-right: 120px;
      }
    }

    &--first {
      @include media-breakpoint-down("md") {
        display: none;
      }
    }
  }

  &__logo {
    color: $c-white;
    height: 41px;
    width: 184px;
    display: block;
    margin-top: 4px;
  }

  &__origin {
    margin-top: 24px;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }

  &__flag {
    margin-left: 8px;
  }

  &__title {
    @extend %new-h3;
    color: $c-white;
    margin-bottom: 16px;
  }

  &__link {
    @extend %new-h4;
    display: block;
    color: rgba($color-white, .8);
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-lower {
    display: block;
    @extend %new-h4;
    color:rgba($c-white, .6);
    @include media-breakpoint-down("sm") {
      font-size: 1.4rem;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    &--first {
      margin-right: auto !important;
    }
  }
}
