@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.wrapper {
  position: relative;
  width: 100%;

  &__content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: $grid-width;
  }

  &--light {
    background-color: $c-base;
  }

  &--white {
    background-color: $c-white;
  }

  &--silver {
    background: $c-silver;
  }

  &--none {
    padding-left: 0;
    padding-right: 0;
  }

  &--small {
    padding-left: 24px;
    padding-right: 24px;

    @include media-breakpoint-up("md") {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  &--normal {
    padding-left: $grid-padding-mobile;
    padding-right: $grid-padding-mobile;

    @include media-breakpoint-up("md") {
      padding-left: $grid-padding-desktop;
      padding-right: $grid-padding-desktop;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0;
  }
}
