@import "src/global/typography";

.conversation-preview {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 2 * $mainsize 2 * $mainsize 1.5 * $mainsize $mainsize;

  &--unreaded {
   
    .conversation-preview__message{
      color: $c-black;
      font-weight: 700;
    }
    .conversation-preview__image{
      outline: 1px solid $c-gray;
      outline-offset: 5px;
      animation: pulse 1.5s infinite;
    }
  }

  &__text-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: $mainsize;
  }

  &__title {
    display: -webkit-box;
    max-width: 21.75 * $mainsize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @extend %new-top-menu;
  }

  &__message {
    @extend %new-mobile-paragraph;
    display: -webkit-box;
    max-width: 30 * $mainsize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $color-grey1;
    & + *{
      margin-top: 2 * $mainsize;
    }
   
  }

  &--selected,
  &:hover {
    background: $color-grey0;
    border-radius: $mainsize;
  }
}

@include media-breakpoint-down("sm") {
  .conversation-preview {
    padding-top: 1.5 * $mainsize;
  }
}

.conversation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__from {
    @extend %new-mobile-paragraph;
  }

  &__time {
    opacity: 0.7;

    @extend %new-mobile-paragraph;
  }
}

@keyframes pulse {
	0% {
    outline-offset: -1px;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
    outline-offset: 5px;
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
    outline-offset: -1px;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
