@import 'src/global/variables';

.conversation {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__messages-list {
        display: flex;
        flex: 1 1 100%;
        padding: 2 * $mainsize 3 * $mainsize;
        overflow-y: auto;
        flex-direction: column;
        &--has-messages {
            > :first-child {
                margin-top: auto;
            }
        }
    }
}
