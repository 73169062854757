@import "src/global/grid.scss";

.host-container {
  &__experiences {
    margin-bottom: 48px;

    @include media-breakpoint-up("md") {
      margin-bottom: 170px;
    }
  }
}
