@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.calendar {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-color: #FBFAF9;

    @include media-breakpoint-up("lg") {
      padding: 32px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__calendar {
    height: 730px;
  }

  &__card-wrapper {
    margin-top: 24px;
    width: 100%;

    @include media-breakpoint-up("lg") {
      max-width: 343px;
      margin-top: 0;
      margin-left: 24px;
    }
  }

  &__info {
    text-align: center;
    margin-top: 24px;

    @extend %new-h3;
  }

  &__day-wrapper {
    padding: 16px;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid rgba($c-black, 0.1);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    h2 {
      @extend %new-h3;
    }

    h3 {
      @extend %new-h4;

      margin-top: 24px;
      margin-bottom: 12px;
    }

    p {
      @extend %new-small-paragraph;

      color: rgba($c-black, 0.6);
      margin-bottom: 24px;
    }

    form {
      margin-bottom: 24px;
    }
  }

  &__cards-block {
    h3 {
      @extend %new-h4;

      color: $c-black;
      font-weight: $weight-medium;
      margin-bottom: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}