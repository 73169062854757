@import "src/global/grid";

.quick_trip_cards {
    display: flex;
    gap: 16px;
    flex-direction: column;
    @include media-breakpoint-up("sm") {
        margin-top: -150px;
        flex-direction: row;
    }

    & > *{
        flex: 0 1 50%;
    }

    &__wrapper {
        z-index: 1;
    }
}
