@import 'src/global/grid';
@import 'src/global/variables';

.browse {
    position: relative;
    min-height: 100vh;
    width: 100%;
    
    &__background-image {
        position: absolute;
        width:100%;
    }

    &__container {
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__map {
        padding-bottom: 8 * $mainsize;
        @include media-breakpoint-up('md') {
            margin-top: 504px;
            min-height: 710px;
            background-color: $color-bg4;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 32px;

        a {
            color: $c-blue;
        }
    }

    &__header{
        top: -60px;
        position: absolute;
        color:$c-white;
    }

  }