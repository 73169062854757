@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.what-provide-card {
  background-color: $c-light-gray-2;
  padding: 32px 24px 24px;
  border-radius: 8px;

  &__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 32px;
  }

  &__name {
    @extend %new-h3;
    margin-bottom: 16px;
    @include media-breakpoint-down("sm") {
      font-size: 2.4rem;
      br {
        content: " ";
        display: none;
      }
    }
  }

  &__description {
    @extend %new-small-paragraph;
  }
}
