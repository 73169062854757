@import "src/global/variables";
@import "src/global/grid.scss";

.faq {
  margin-top: -1px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $c-dark-orange;

  @include media-breakpoint-up("sm") {
    padding-top: 111px;
    padding-bottom: 126px;
  }

  &__header {
    margin-bottom: 16px;
    color: $c-white;

    @include media-breakpoint-up("sm") {
      margin-bottom: 56px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
    & > div {
      flex: 0 0 100%;
      padding: 0 20px;
    }

    @include media-breakpoint-up("sm") {
      flex-direction: row;
      & > div {
        flex: 0 0 50%;
      }
    }
  }
}
