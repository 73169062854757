@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.search-bar {
    position: relative;
    display: flex;
    background-color: $c-white;
    border-radius: 8px;
    padding: 4px 40px 4px 4px;
    width: 100%;
    border: 1px solid $c-semi-light-gray;
    max-height: 50px;
    color: black;
    opacity: 1;

    @include media-breakpoint-up('md') {
        padding: 4px;
    }

    &--hidden {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        pointer-events: none;
    }

    &__filter {
        display: block;

        &--location {
            width: 100%;
            max-width: calc(100% - 240px);

            @include media-breakpoint-down('sm') {
                max-width: calc(100% - 110px);
            }
        }

        &--activity {
            padding: 0 0 0 5px;
            border-left: 1px solid $c-semi-light-gray;
            width: 110px;
            flex-shrink: 0;

            @include media-breakpoint-up('md') {
                width: 150px;
            }
        }
    }

    &__button {
        flex: 0 0 90px;
    }

    &__secondary-button {
        position: absolute;
        right: 0;
        top: 50%;
        padding: 0;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);

        svg {
            color: $c-green;
            width: 20px;
            height: 20px;
        }

        @include media-breakpoint-up('md') {
            display: none;
        }
    }

    &--wide {
        .search-bar__button {
            flex: 0 0 120px;
        }

        .search-bar__filter--location {
            max-width: calc(100% - 270px);
        }
    }
    &--search-page {
        padding: 4px ;
        box-shadow: 0px 2px 7px rgba(164, 164, 164, 0.25);
        .search-bar__filter--location {
            @include media-breakpoint-up('md') {
                max-width: none;
            }
        }
    }

    @include media-breakpoint-down('sm') {
        &__button {
            display: none;
        }
    }
}
