@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";


.container {
  display: flex;
  margin-top: 42px;
  @include media-breakpoint-down('sm') {
    flex-direction: column-reverse;
  }
}

.content {
  flex: 1;
  margin-right: 32px;
  @include media-breakpoint-down('sm') {
    margin-right: 0px;
  }
}

.sidebar {
  width: 424px;
  @include media-breakpoint-down('sm') {
    width: 100%;
    margin-bottom: 32px;
  } 
}

.block {
  border-radius: 8px;
}

.guide {
  width: 100%;
}

.editPublicProfileLink {
  display: flex;
  width: 100%;
  flex-direction: column;
  .customUrlInput {
    &.focused {
      .staticInputUrl {
        opacity: .6;
      }
    }
    input {
      padding-left: 0 !important;
    }
  }
  .staticInputUrl {
    padding-left: 16px;
  }
  .copyButton {
    color: $color-black;
    margin-right: 16px;
    font-size: 1.5rem;
    font-weight: 500;
    border-bottom: thin solid rgba($color-black, 0.6);
    opacity: .6;
  }
  .description {
    margin-bottom: 28px;
    padding-right: 105px;
    opacity: .7;
  }
  .editProfileButton {
    margin-bottom: 12px;
  }
}