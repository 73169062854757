@import 'src/global/typography';

.booking-state-text {
    color: $c-orange;
    @extend %new-label;
    
    &--accepted {
        color: #167307;
    }
    &--rejected {
        color: $c-orange;
    }
    &--pending {
        color: #043f76;
    }
    &--cancelled {
        color: #5f5f5f;
    }
}
