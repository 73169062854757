@import "src/global/variables";
@import "src/global/grid";

.image {
  position: relative;
  &__img {
    border-radius: $mainsize;
    object-fit: cover;
    width: 100%;
    height: auto;
    transition: opacity 0.15s ease-in;
    opacity: 0;
  }

  &--ready {
    .image__img {
      opacity: 1;
    }
  }

  &--with-click {
    cursor: pointer;
  }

  &--with-fixed-ratio {
    width: 100%;

    .image__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--with-full-ratio {
    height: 100%;

    .image__img {
      height: 100%;
    }
  }

  &--rect {
    .image__img {
      border-radius: 0;
    }
  }

  &--round {
    .image__img {
      border-radius: 50%;
      height: 100%;
    }
  }

  &--round-small {
    .image__img {
      border-radius: $mainsize * 2;
    }
  }
}
