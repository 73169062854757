.full-screen {
  min-height: 100vh;
  width: 100vw;
}

/* size */
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@include media-breakpoint-up("md") {
  .w-md-100 {
    width: 100%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }

  .w-md-25 {
    width: 25%;
  }

  .w-md-auto {
    width: auto;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }
}

@include media-breakpoint-up("lg") {
  .w-lg-100 {
    width: 100%;
  }

  .w-lg-75 {
    width: 75%;
  }

  .w-lg-50 {
    width: 50%;
  }

  .w-lg-25 {
    width: 25%;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

/* accessibility */
.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  cursor: not-allowed;
}

.icon-1_5 {
  width: 18px !important;
  height: 18px !important;
}

.icon {
  width: 12px !important;
  height: 12px !important;
}

.icon-2 {
  width: 24px !important;
  height: 24px !important;
}

.icon-3 {
  width: 36px !important;
  height: 36px !important;
}

.icon-4 {
  width: 48px !important;
  height: 48px !important;
  display: inline-flex;
}

.flex-overflow-fix {
  min-width: 0; // required to prevent children from overflowing flex container
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-fill {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.bold {
  font-weight: bold;
}

.bg-light {
  background-color: $color-light;
}

.bg {
  background-color: $color-bg;
}

.registrationModal {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  max-width: calc(100vw - #{$mainsize * 8});
  max-height: 80vh;
  overflow: auto;

  > * {
    width: 100%;
  }

  @include media-breakpoint-down("sm") {
    max-width: calc(100vw - #{$mainsize * 6});

    > div > div > div {
      width: 100%;
    }
  }
}

.loginModal {
  display: flex;
  flex-direction: column;
  padding: $grid-padding-sm;
  min-width: 300px;
  max-height: 80vh;
  overflow: auto;
}

@include media-breakpoint-up('md') {
  .mapboxgl-popup-close-button {
    display: none !important;
  }
}
