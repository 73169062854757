@import 'src/global/variables.scss';
@import 'src/global/typography.scss';

:global .StripeElement {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px 16px;
    & + div {
        max-width: 50px;
        min-height: 100%;
        flex: 0 0 50px;
        align-self: stretch;
        svg {
            width: 20px;
        }
    }
}

:global .PhoneInput {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    .PhoneInputCountry {
        margin-left: 16px;
        margin-right: 8px;
        & + input {
            padding-left: $mainsize !important;
        }
    }
    .PhoneInputInput {
        padding: 16px 16px 16px 4px;
    }
}

.form-field {
    display: block;
    margin-bottom: 16px;
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    &__message {
    }

    &__description {
        @extend %new-extra-small-text;
        margin-top: $mainsize;
    }

    &--hidden {
        height: 0;
        overflow: hidden;
        margin: 0;
        * {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    &--radio,
    &--checkbox {
        cursor: pointer;
        .form-field__wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .form-field__header {
            margin-top: 2px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: left;
        }
        & + .form-field--radio {
            margin-top: -8px;
        }
    }

    &--incremental {
        pointer-events: none;
        button {
            pointer-events: auto;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__input {
        @extend %new-small-text;
        // we need to increase that to prevent safari from zooming in input fields
        font-size: 1.6rem;
        border: 1px solid $c-light-gray;
        border-radius: 8px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;

        input:not([type='radio']):not([type='checkbox']),
        textarea,
        select {
            padding: 16px 16px;
            width: 100%;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px $c-very-light-gray inset;
                & + div {
                    -webkit-box-shadow: 0 0 0px 1000px $c-very-light-gray inset;
                }
            }
        }
        textarea {
            resize: none;
            min-height: 100px;
            line-height: 1.4;
            @include media-breakpoint-down('xs') {
                min-height: 140px;
            }
        }

        select {
            // display: none;
        }
        &--select {
            &:after {
                z-index: 0;
                content: '';
                position: absolute;
                right: 10px;
                width: 10px;
                height: 5px;
                background-color: $c-dark-gray;
                clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            }
            select {
                z-index: 1;
            }
        }

        &--multiselect {
            position: relative;
            overflow: visible;
            min-height: 48px;
            display: flex;
            align-items: center;
            & > div {
                padding: 8px;
            }
            input {
                padding: 8px !important;
            }
            &:after {
                z-index: 0;
                content: '';
                position: absolute;
                right: 10px;
                width: 10px;
                height: 5px;
                background-color: $c-dark-gray;
                clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            }

            :global(.multiseect-dropdown) {
                height: auto;
                background-color: $color-white;
                z-index: 10;
                border: thin solid $c-dark-gray;
            }
        }

        &--focused {
            border-color: $c-dark-gray;
        }

        &--has-icon {
            input,
            textarea {
                & + div {
                    max-width: 50px;
                    min-height: 100%;
                    flex: 0 0 50px;
                    align-self: stretch;
                    svg {
                        width: 20px;
                    }
                }
            }
            &.form-field--has-success {
                input,
                textarea {
                    & + div {
                        svg {
                            width: 18px;
                        }
                    }
                }
            }
        }

        &--has-error {
            color: $c-red;
            border-color: $c-red;
        }

        &--has-success {
            color: $c-green;
            border-color: $c-green;
        }

        &--hidden {
            border: none;
            input {
                padding: 0;
            }
        }

        &--disabled {
            border: none;
        }

        &--incremental {
            @extend %new-top-menu;
            border: none;
            margin-bottom: 0;
            input {
                @extend %new-h4;
                width: auto !important;
                max-width: 25px;
                text-align: center;
                padding: 0 !important;
                margin: 0 5px;
                pointer-events: none;
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }

            button {
                padding: 0;
                width: 24px;
                height: 24px;
                flex: 0 0 24px;
            }
        }

        &--radio,
        &--checkbox {
            position: relative;
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 10px;
            border: none;
            overflow: auto;
            border-radius: 0;
            &.form-field__input--disabled {
                opacity: 0.7;
            }
            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                border: 2px solid $c-black;
                // border-color: $c-black;
                width: 20px;
                height: 20px;
                // border-width: 2px;
                border-radius: 2px;
                // transform: translate3d(-2px, -2px, 0);
            }
            &:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                left: 0;
                top: 0;
                transform: translate3d(5px, 5px, 0);
            }
        }

        &--radio {
            // border-radius: 20px;
            &:before {
                border-radius: 100%;
            }
            &:after {
                border-radius: 100%;
            }
        }

        &--checked {
            &:after {
                background-color: $c-black;
            }
        }

        &--minimal {
            border: none;
            border-bottom: 1px solid $c-light-gray;
            border-radius: 0;
            &.form-field__input--focused {
                border-bottom: 1px solid $c-dark-gray;
            }
            input:not([type='radio']):not([type='checkbox']) {
                padding: 1px 2px 3px;
            }
        }

        &--compact {
            &.form-field__input--text,
            &.form-field__input--date,
            &.form-field__input--number,
            &.form-field__input--select {
                input:not([type='radio']):not([type='checkbox']),
                textarea,
                select {
                    padding: 18px 16px 6px;
                    height: 40px;
                }
            }
        }

        &--vertical {
            &.form-field__input--checkbox {
                width: 3 * $mainsize;
                min-width: 3 * $mainsize;
                height: 3 * $mainsize;
                opacity: 0.6;
                &:before {
                    background-color: rgba(47, 51, 59, 0.07);
                    border-radius: 100%;
                    border: none;
                    width: 3 * $mainsize;
                    height: 3 * $mainsize;
                }
                &:after {
                    width: 10px;
                    height: 10px;
                    transform: translate3d(7px, 7px, 0);
                    background: url('../../../assets/icons/icon_close.svg');
                    background-size: 10px 10px;
                }
            }
            &.form-field__input--checked {
                &:after {
                    width: 14px;
                    height: 14px;
                    background: url('../../../assets/icons/icon_tick_fill.svg');
                    background-size: 14px 14px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &--compact {
        &.form-field--text,
        &.form-field--date,
        &.form-field--number,
        &.form-field--select {
            .form-field__header {
                position: absolute;
                top: 4px;
                left: 2 * $mainsize;
                font-size: 8px;
                text-transform: uppercase;
            }
        }
        &.form-field--radio {
            .form-field__wrapper {
                justify-content: center;
                align-items: center;
                flex-direction: row;
                padding: $mainsize 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                background: $c-semi-light-gray;
                border: 1px solid $c-semi-light-gray;
            }
            .form-field__header {
                font-size: 1.3rem;
                font-weight: 500;
                color: $c-dark-gray;
            }
            &.form-field--checked {
                .form-field__wrapper {
                    background: $c-white;
                }
                .form-field__header {
                    color: $c-black;
                    font-weight: 700;
                }
            }
            .form-field__input {
                display: none;
            }
            & ~ .form-field--radio {
                .form-field__wrapper {
                    border-radius: 0;
                }
            }
            & + .form-field--radio:last-child {
                .form-field__wrapper {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }

        &.form-field--incremental {
            .form-field__wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                text-transform: uppercase;
                border: 1px solid $c-semi-light-gray;
                padding: $mainsize 2 * $mainsize $mainsize 2 * $mainsize;
                border-radius: $mainsize;
            }
            .form-field__header {
                margin: 0 $mainsize 0 0;
                font-size: 1rem;
            }
            &.form-field--has-error {
                .form-field__wrapper {
                    border: 1px solid $c-red;
                }
                .form-field__header {
                    color: $c-red;
                }
            }
        }
    }

    &--vertical {
        &.form-field--checkbox {
            .form-field__wrapper {
                flex-direction: column;
                align-items: center;
            }
            .form-field__input {
                margin-right: 0;
                margin-top: $mainsize;
            }
        }
    }

    &__append {
        svg {
            width: 10px;
            height: 10px;
            margin-right: 0;
        }
    }
    button.form-field__append {
        padding-right: 2px;
        padding-left: 2px;
    }
    svg.form-field__prepend {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    &--has-error {
        .form-field__description{
            color: $c-red;
        }
    }
    &--minimal {
        .form-field__description {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateY(100%);
        }
    }
}
