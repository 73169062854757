@import "src/global/variables";
@import "src/global/typography";
@import "src/global/grid";

.lens {
    &__content-overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;

        padding: 5 * $mainsize;

        @include media-breakpoint-down("sm") {
            padding: 2 * $mainsize;
        }
    }

    &__left-up {
        color: #fffefe;
        width: max-content;
    }

    &__down {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;
    }

    &__left-down {
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__image {
        min-height: 300px;
    }

    &__button {
        @include media-breakpoint-up("sm") {
            max-width: 200px;
        }
        align-self: flex-end;
        flex-grow: 1;
    }

    &__sub-header {
        font-size: 15.8774px;
        line-height: 105%;
    }
    &__footer {
        @extend %new-h2;
        color: $color-white;
    }

    &__sub-footer {
        @extend %new-paragraph;
        color: $color-grey0;
    }

    &__header {
        font-family: $ff-rokkitt;
        font-weight: 900;
        font-size: 50.5189px;
        line-height: 69%;
    }
}
