@import "src/global/variables";
@import "src/global/grid.scss";
@import "src/global/typography.scss";

.stay-booked {
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $c-base;

  @include media-breakpoint-up("sm") {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    @extend %font-secondary-bold;
    font-size: 2.7rem;
    line-height: 3.2rem;
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-up("sm") {
      font-size: 4.8rem;
      line-height: 6.5rem;
      margin-bottom: 48px;
    }
  }
}
