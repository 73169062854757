@import "src/global/grid";
@import "src/global/typography";

.posts-related {
  margin-top: 48px;
  margin-bottom: 72px;

  @include media-breakpoint-up("sm") {
    margin-top: 96px;
    margin-bottom: 28px;
  }

  &__header {
    @extend %new-h1;

    margin-bottom: 24px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 32px;
    }
  }

  &__cards-wrapper {
    @include media-breakpoint-up("sm") {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__single-card {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;

      @include media-breakpoint-up("sm") {
        margin-bottom: 48px;
      }
    }

    @include media-breakpoint-up("sm") {
      width: calc((100% - 2 * 24px) / 3);
      margin-bottom: 0;
    }
  }
}
