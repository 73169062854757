@import 'src/global/variables';
@import 'src/global/typography';
@import 'src/global/grid';

.event-details {
    display: flex;

    // 100% height is problematic
    // in card list view
    height: 100%;

    flex-direction: column;
    &__address {
        @extend %new-h4;
    }
    &__title {
        @extend %new-h4;
        margin-bottom: 4px;
    }

    &__preview {
        @extend %new-extra-small-text;
        margin-bottom: 10px;
    }
    &__details {
        @extend %new-extra-small-text;
        color: $c-light-gray-3;
        margin-top: auto;
        &:before {
            content: '';
            display: block;
            position: relative;
            height: 1px;
            width: 30px;
            background: $c-light-gray-3;
            opacity: 0.5;
        }

        span {
            display: inline-block;
            margin-top: 3px;
            & + span {
                &:before {
                    content: '·';
                    display: inline-block;
                    margin: 3px 3px 0;
                }
            }
        }
    }

    &__summary {
        margin-top: 2 * $mainsize;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--price {
            @extend %new-small-text;

            span {
                @extend %new-top-menu;
            }
        }
        &--rating {
            margin-left: 5px;
        }
    }

    &__cta {
        width: 100%;
        margin-top: $mainsize * 2;
        margin-bottom: $mainsize;
    }

    @include media-breakpoint-only('xs') {
        height: auto;
        &__details {
            margin-top: $mainsize;
        }
        &__summary {
            margin-top: $mainsize;
        }
    }
}
