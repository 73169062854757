@import "src/global/variables";

.stylish-button {
    &__content {
        display: flex;
        align-items: center;
        height: 38px;
        width: 100%;
    }

    &__heading {
        font-weight: normal;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        color: $color-grey2;
    }

    &__text-container {
        text-align: left;
        flex-grow: 1;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    &__icon {
        background: $color-grey0;
        border-radius: 100%;
        font-size: 32px !important; // to increase icon size without much hussle
        padding: 6px;
    }
}
