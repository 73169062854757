@import 'src/global/variables';
@import 'src/global/grid.scss';

.floating-component {
    position: fixed;
    height: auto;
    box-shadow: 0 -15px 35px rgba($color-black, 0.05);
    z-index: 4;
    width:100%;
    left:0;
    right:0;
    &--footer {
        bottom: 0;
    }

    &--relative-on-mobile {
        @include media-breakpoint-down('sm') {
            position: relative;
        }
    }

    &--header {
        top: 0;
    }
}
