@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.post-container {
  margin: 24px 0;

  &__image-container {
    position: relative;
    margin-bottom: 32px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 68px;
    }
   
  }

  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    max-width: 800px;
    padding: 40px;
      
  }

  &__image-wrapper {
    width: 100%;
    &:after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.1);
      border-radius: $mainsize;
    }
  }

  &__image {
    height: 232px;
    @include media-breakpoint-up("sm") {
      height: 400px;
    }
  }

  &__article {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down("md") {
      display: block;
    }
  }

  &__controls {
    display: block;
    margin-right: 140px;

    @include media-breakpoint-down("md") {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      margin-right: 0;
    }
  }

  &__share-text {
    display: block;
    margin-bottom: 16px;
    font-weight: $weight-medium;

    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  &__back-button {
    margin-bottom: 48px;

    @include media-breakpoint-down("md") {
      margin-bottom: 0;
    }
  }

  &__back-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  &__share-links {
    display: flex;
    align-items: center;
  }

  &__share-link {
    display: block;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__share-icon {
    width: 24px;
    height: 24px;
    color: $c-very-dark-gray;
  }

  &__date {
    @extend %new-small-paragraph;

    display: block;
    color: $c-white;
    opacity: 0.9;
    margin-bottom: 16px;
  }

  &__title {
    @extend %new-h1;

    font-family: $ff-default;
    color: $c-white;
  }

  &__main {
    @extend %font-primary-regular;
    font-size: 2rem;
    line-height:3.2rem;
    width: 100%;
    @include media-breakpoint-down("sm") {
      font-size: 1.7rem;
      line-height:2.5
      rem;
    }
    & > *:first-child{
      margin-top:0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 40px;
      margin-bottom: 16px;
    }
    h1{
      @extend %new-h1;
    }
    h2{
      @extend %new-h2;
    }
    h3{
      @extend %new-h3;
    }
    h4{
      @extend %new-h4;
    }

    p{
      margin-bottom: 16px;
    }

    img{
      margin-bottom: 40px;
    }

    a{
      color:$c-black;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }

    img {
      border-radius: 8px;
    }

    figure {
      margin-top: 40px;
      margin-bottom: 40px;
      img{
        margin-bottom: 0;
      }
      figcaption{
        text-align: center;
        @extend %new-extra-small-text;
        margin-top: 5px;
      }
    }
  }
}
