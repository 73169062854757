@import "src/global/variables";

.avatar {
  border-radius: 100%;
  text-align: center;
  color: $c-white;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;

  &--x-huge {
    width: 10 * $mainsize;
    min-width: 10 * $mainsize;
    
    height: 10 * $mainsize !important;
  }

  &--huge {
    width: 6 * $mainsize;
    min-width: 6 * $mainsize;
    height: 6 * $mainsize !important;
  }

  &--medium {
    width: 5 * $mainsize;
    min-width: 5 * $mainsize;
    height: 5 * $mainsize !important;
  }

  &--compact {
    width: 4 * $mainsize;
    min-width: 4 * $mainsize;
    height: 4 * $mainsize !important;
  }
}
