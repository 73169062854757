@import 'src/global/variables';
@import 'src/global/grid';

.powered-by-button {
    z-index: 2;
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
    span {
        opacity: 0.6;
        display: block;
    }
    &__logo {
        height: 31px;
        width: 138px;
        flex: 0 0 138px;
        display: block;
        margin-left: 2 * $mainsize;
        margin-top: 3px;
    }

    &__logo-small {
        height: 31px;
        width: 31px;
        flex: 0 0 31px;
        display: none;
    }

    @include media-breakpoint-down('sm') {
        flex-direction: column;
        align-items: flex-end;
        span {
            font-size: 1.5rem;
            margin-bottom: -8px;
        }
        &__logo {
            margin-left: 0;
            flex: 0 0 auto;
            margin-top: 0;
        }
    }

    @include media-breakpoint-down('xs') {
        span{
            display:none;
        }
        &__logo-small {
            display:block;
        }
        &__logo {
            display:none;
        }
    }
}
