@import 'src/global/variables';
@import 'src/global/typography.scss';

.accordion {

  &__button {
    @extend %new-small-text;

    display: block;
    width: 100%;
    text-align: left;
    color: rgba($c-black, 0.6);
    margin-bottom: 32px;
  }

  &__content {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
}