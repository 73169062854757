@import "src/global/variables";
@import "src/global/grid";

.edit-trip {

    &__wrapper {
        display: flex;
        column-gap: 8px;
        width: 100%;
        border-radius: 8px;
        background-color: rgba($c-light-gray-2, 0.71);
        overflow: hidden;

        @include media-breakpoint-down('xs') {
            flex-direction: column;
            height: auto;
        }
    }

    &__image {
        position: relative;
        max-width: 368px;
        width: 100%;
        min-height: 240px;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-down('xs') {
            max-width: none;
        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        width: 100%;
        padding: 16px;
    }

    &__description {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 24px;
            line-height: 32px;
        }

        p {
            font-size: 13px;
            line-height: 16px;
            opacity: 0.6;
        }
    }

    &__location {
        margin-bottom: 13px;
        
    }

    &__duration {
        margin-bottom: 34px;
    }   

    &__total {
        font-size: 15px;
        line-height: 16px;
        display: inline-block;
    }

    &__price {
        font-size: 17px;
        line-height: 24px;
        font-weight: 900;
        opacity: 1;
    }

    &__published {
        font-weight: 500;
        color: $color-green;
        display: flex;
        align-items: center;
        column-gap: 4px;
        height: fit-content;
        margin-top: 6px;
        margin-left: 8px;

        &-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-green;
        }
    }

    &__unpublished {
        font-weight: 500;
        color: $color-grey3;
    }

    &__buttons {
        display: flex;
        column-gap: 8px;
        margin-top: 24px;

        button {
            max-width: 132px;
            width: 100%;
            padding: 12px 24px;
        }
    }


}