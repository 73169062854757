@import "src/global/variables.scss";
@import "src/global/typography.scss";

.form-message{
  @extend %new-label;
  text-align: right;
  &--error{
    color: $c-red;
  }
  &--success{
    color: $c-green;
  }
}