@import "src/global/typography";

.no-messages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4 * $mainsize;

  &__photo {
    display: flex;
    justify-content: center;
  }

  &__from {
    margin: 2 * $mainsize 0;

    @extend %new-top-menu;
  }

  &__message {
    opacity: 0.7;
    max-width: 32 * $mainsize;
    margin-left: auto;
    margin-right: auto;

    @extend %new-paragraph;
  }
}
