@import "src/global/variables";

.selectedItems {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
}

.inputBox {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    input {
        flex: 1;
        min-width: 200px;
    }
}

.dropdown {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    visibility: hidden;
    z-index: 200;
    max-height: 200px;
    overflow-y: scroll;
    &.show {
        visibility: visible;
    }
    .dropdownOption {
        width: 100%;
        padding: 10px;
        text-align: left;

        &:not(.selected):hover {
            color: #000;
            cursor: pointer;
        }
        &.selected {
            opacity: .6;
        }
    }
}

.tag {
    height: 32px;
    background-color: $color-grey0;
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 0 18px 0 12px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .icon {
        position: absolute;
        right: 5px;
        top: 5px;
        height: 7px;
        width: 7px;
        color: $color-bg;
    }
}