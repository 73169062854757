@import 'src/global/variables.scss';
@import 'src/global/typography.scss';
@import 'src/global/grid.scss';

.details-section {
    margin-bottom: -5 * $mainsize;
    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5 * $mainsize;
    }
    &__title {
        flex: 0 0 30%;
        margin-right: 2 * $mainsize;
        @extend %new-top-menu;
    }
    &__value {
        flex: 0 0 70%;
        @extend %new-h4;
        margin-top: -3px;
        span {
            display: inline-block;
            margin-right: 2 * $mainsize;
            line-height: 3rem;
            &:before {
                content: '·';
                display: inline-block;
                margin-right: 1 * $mainsize;
                font-weight: 700;
            }
        }
    }

    @include media-breakpoint-down('sm') {
        margin-bottom: -3 * $mainsize;
        &__item {
            margin-bottom: 3 * $mainsize;
        }
        &__title {
            flex: 0 0 40%;
        }
        &__value {
            flex: 0 0 60%;
        }
    }

    @include media-breakpoint-down('xs') {
        &__item {
            flex-wrap: wrap;
        }
        &__title,
        &__value {
            flex: 0 0 100%;
            span {
                display: block;
            }
        }

        &__value {
            margin-top: 5px;
        }
    }
}
