@import 'src/global/variables';
@import 'src/global/typography';

.booking-cancel-calculation-modal {
    &__info {
        padding: 24px 40px;
        text-align: center;
        @extend %new-paragraph;
    }
    &__buttons {
      display: flex;
      column-gap: 8px;
      margin-top: 24px;

      button {
          width: 100%;
      }
  }
}
