@import 'src/global/variables';

.container {
    border-color: $c-light-gray;
    &.incompleted {
       border-color: $c-electric-brown;
    }
    border-width: thin;
    border-style: solid;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.section {
    min-height: 90px;
    display: grid;
    grid-template-areas: 
        'heading status'
        'description status'
    ;
    &:not(.sectionCompleted) .status {
        color: $c-electric-brown;
        
    }
    &.sectionCompleted .status {
        color: $c-green;
    }
    &.hasActions {
        grid-template-areas: 
        'heading status'
        'description status'
        'actions actions'
    ;
    }
    &:not(:last-of-type) {
        margin-bottom: 32px;
    }
    .heading {
        grid-area: heading;
        font-weight: 500;
        font-size: 1.7rem;
        line-height: 1.41;
    }
    .status {
        grid-area: status;
        font-weight: 500;
        font-size: 1.7rem;
        line-height: 1.41;
        color: var(--status-color);
    }
    .description {
        grid-area: description;
        opacity: .7;
    }
    .actions {
        grid-area: actions;
        margin-top: 16px;
    }
}

.actionButton {
    width: 100%;
}