@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography.scss";

.host-location {
  margin-bottom: 48px;

  @include media-breakpoint-up("md") {
    margin-bottom: 124px;
  }

  &__header {
    @extend %new-h2;

    margin-bottom: 16px;

    @include media-breakpoint-up("md") {
      margin-bottom: 48px;
    }
  }

  &__map {
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__map-pin {
    background-image: url("../../../../assets/icons/icon_pin.svg");
    background-size: cover;
    width: 50px;
    height: 50px;
  }
}
