@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.account-navbar {
    border-top: 1px solid rgba($c-black, 0.05);
    border-bottom: 1px solid rgba($c-black, 0.05);

    &__content {
        width: 100%;
        color: $c-dark-gray;
        overflow-x: auto;
        overflow-y: hidden;
        text-align: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        display: inline-block;
        margin-right: 15px;
        padding-top: 12px;
        padding-bottom: 10px;

        &:not(:first-child) {
            margin-left: 15px;
        }

        &--active {
            color: $c-red;
            border-bottom: 2px solid $c-red;
        }
    }

    &__become-guide {
        padding: 0 8px;
        height: 36px;
    }
}
