@import 'src/global/variables';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.page {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    @extend %new-h2;
    text-align: center;
    margin-bottom: 3 * $mainsize;
}

.content {
    max-width: 500px;
    padding: 80px 24px;
}

.container {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
