@import 'src/global/variables.scss';
@import 'src/global/grid';

.account-details {
    &__wrapper {
        position: relative;
        margin-top: 40px;
        margin-bottom: 42px;
    }

    &__profile {
        display: flex;
        align-items: flex-start;
        column-gap: 44px;
        width: 100%;

        @include media-breakpoint-down('xs') {
            flex-direction: column;
        }
    }

    &__image {
        min-width: 200px;
        line-height: 0;
        label {
            display: block;
            margin-bottom: 8px;
        }
        @include media-breakpoint-down('xs') {
            width: 100%;
        }
    }

    &__name {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 100%;
    }

    &__inputs {
        display: flex;
        column-gap: 16px;
        justify-content: space-between;
        align-items: center;

        label {
            flex-grow: 1;
        }

        @include media-breakpoint-down('xs') {
            flex-direction: column;

            label {
                width: 100%;
            }
        }

        input:disabled {
            opacity: 1;
            border: 1px solid $c-light-gray;
            border-radius: 8px;
        }
    }

    &__phone-input {
        div {
            padding: 0;
        }
    }
    &__email-input {
        opacity: 0.5;
        border: 1px solid $c-light-gray;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 24px;
        font-size: 15px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__deactivate-button {
        padding: 0 0 8px 0;
        margin: auto 0;
        border-bottom: 2px solid $color-black-full;
        border-radius: 0;

        @include media-breakpoint-down('xs') {
            font-size: 1.3rem;
        }
    }
}
