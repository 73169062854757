@import "src/global/variables";
@import "src/global/grid.scss";
@import "src/global/typography.scss";

.not-found-page {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__header {
    @extend %new-h1;

    font-size: 12.8rem;
    line-height: 100%;
    margin-bottom: 8px;
    color: $c-white;

    @include media-breakpoint-down("sm") {
      font-size: 8rem;
    }
  }

  &__subheader {
    @extend %new-h3;

    color: $c-white;
    margin-bottom: 32px;
  }

  &__link {
    margin-top: 24px;
    display: inline-block;
  }
}
