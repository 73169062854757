@import 'src/global/typography';
@import 'src/global/variables';

.browse-map {
    &__wrapper {
        display: flex;
        width: 100%;
        padding-top: 48px;
        padding-bottom: 56px;
        margin-bottom: -96px;
        background-color: $color-bg4;

        @include media-breakpoint-down('sm') {
            margin-bottom: -56px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__link {
        font-size: 19px;
        line-height: 30px;
        color: $c-blue;
        max-width: 232px;
        width: 100%;
    }

    &__links-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, max(232px));
        grid-row-gap: 8px;
        grid-column-gap: 16px;
    }

    &__pursuit {
        padding-bottom: 40px;
        min-height: 154px;
    }
}
