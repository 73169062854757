@import 'src/global/variables';
@import 'src/global/typography';
@import 'src/global/grid';

.carousel {
    > div {
        height: 100%;
    }

    &--loading{
        :global(.CarouselItem) > div {
            transition-duration: 0ms !important;
        }
    }
    
    .carousel__button {
        background-color: $c-white !important;
        padding: 5px;
        top: calc(50% - 12px) !important;
        color: $c-black;
        &:hover {
            opacity: 1 !important;
        }
    }
    .carousel__indicator {
        color: $c-white;
        opacity: 0.7;
        svg {
            font-size: 10px;
        }
        &--active {
            opacity: 1;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    :global(.CarouselItem) {
        height: 100%;
        > div {
            height: 100%;
        }
    }
}
