@import 'src/global/variables';
@import 'src/global/typography.scss';
@import 'src/global/styles';

.container {
    width: 100%;
    border: 1px solid transparentize($color-black, 0.9);
    border-radius: 8px;
    padding: 32px;
    position: relative;
    h3 {
        margin-bottom: 32px;
    }

    h4 {
        margin-bottom: 16px;
    }
}
.content {
    > * {
        & + * {
            margin-top: 3 * $mainsize;
        }
    }
}

@include media-breakpoint-down('sm') {
    .container {
        padding: 2 * $mainsize;
    }
}
