@import 'src/global/variables';
@import 'src/global/grid';

.image-gallery {
    &--mobile {
    }

    &--list {
    }

    &__overlay {
        transition: opacity 0.2s;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &:hover {
        .image-gallery__overlay {
            opacity: 1;
        }
    }
    &--list{
        & > div{
            margin-bottom: 2 * $mainsize;
        }
    }
    @include media-breakpoint-up('md') {
        &--list {
            display: grid;
            grid-gap: 2 * $mainsize;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: auto;
            > div {
                margin-bottom: 0;
                &:nth-child(3n + 1) {
                    grid-column: span 1;
                }
                &:nth-child(3n + 2) {
                    grid-column: span 1;
                }

                &:nth-child(3n + 3) {
                    grid-column: span 2;
                }
            }

            &.image-gallery--from-one {
                > div {
                    &:nth-child(3n + 1) {
                        grid-column: span 2;
                    }
                    &:nth-child(3n + 2) {
                        grid-column: span 1;
                    }

                    &:nth-child(3n + 3) {
                        grid-column: span 1;
                    }
                }
            }
            .image-gallery__overlay {
                grid-column: span 2;
            }
        }
    }

    @include media-breakpoint-up('md') {
        display: grid;
        grid-gap: 2 * $mainsize;
        &--grid {
            &.image-gallery--1 {
                grid-template-rows: 400px;
            }
            &.image-gallery--2 {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 400px;
            }
            &.image-gallery--3 {
                grid-template-columns: 2fr 1fr 1fr;
                grid-template-rows: 400px;
            }
            &.image-gallery--4 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 400px;
            }
            &.image-gallery--5 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 200px 200px;
                grid-template-areas:
                    'left left topcenter topright'
                    'left left bottomcenter bottomright';
                & > div {
                    &:nth-child(1) {
                        grid-area: left;
                    }
                    &:nth-child(2) {
                        grid-area: topcenter;
                    }
                    &:nth-child(3) {
                        grid-area: topright;
                    }
                    &:nth-child(4) {
                        grid-area: bottomcenter;
                    }
                    &:nth-child(5) {
                        grid-area: bottomright;
                    }
                }
            }
            .image-gallery__overlay {
                grid-area: bottomright;
            }
        }
    }
}
