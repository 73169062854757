@import 'src/global/variables';
@import 'src/global/typography';
.map-gl {
    &__controls {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &--disabled {
        * {
            cursor: default !important;
        }
    }
    &__geocoder {
        // position: absolute;
        // left: 50px;
        // top: 10px;
        // padding:6px;
        // background: $c-white;

        // svg,
        // .mapboxgl-ctrl-geocoder--pin-right {
        //     display: none;
        // }

        // > div,
        // input {
        //     padding: 0;
        //     height: 100%;
        //     width: 100%;
        //     max-width: unset;
        //     background-color: transparent;
        //     border-radius: 0;
        //     box-shadow: none;
        // }

        // input {
        //     height: 18px;
        //     line-height: 18px;

        //     &::placeholder {
        //         color: $color-black;
        //     }
        // }
    }
}
