@import 'src/global/grid';
@import 'src/global/typography';

.abc {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  overflow: hidden;
  border: 1px solid rgba($c-black, 0.1);
  border-radius: 8px;
  position: relative;
  
  &--is-users-booking{
    background: $c-very-light-gray;
  }

  &__status-row,
  &__info-additional,
  &__details-row,
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__status-row {
    align-items: flex-start;
    margin-bottom: 4px;
  }

  &__info-additional {
    margin-bottom: 16px;
  }

  &__note-wrapper {
    @extend %new-extra-small-text;

    margin-top: 8px;
  }

  &__note {
    color: $c-black;
  }

  &__details-wrapper {
    margin-top: auto;
  }

  &__details-row {
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &__status,
  &__type {
    @extend %new-h4;
    @extend %font-primary-medium;

    color: $c-black;
  }

  &__status,
  &__name {
    margin-right: 16px;
  }

  &__name {
    @extend %new-extra-small-text;
  }

  &__guests {
    @extend %new-extra-small-text;

    color: $c-black;
    text-align: right;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 75px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__image {
  }

  &__image-placeholder {
    background-color: gray;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-position: center;
    object-fit: cover;
  }

  &__guest-info {
    position: absolute;
    bottom: 16px;
    left: 16px;
    display: flex;
    align-items: center;
  }

  &__guest-name {
    @extend %new-label;
    @extend %font-primary-medium;

    color: $c-white;
    font-size: 15px;
    line-height: 16px;
    margin-left: 8px;
  }

  &__date-price {
    margin-right: 16px;
  }

  &__date {
    @extend %new-small-text;
    @extend %font-primary-medium;

    margin-bottom: 6px;
  }

  &__price {
    @extend %new-h4;

    color: rgba($c-black, 0.6);

    span {
      @extend %font-primary-medium;

      color: $c-black;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 400px) {
      flex-direction: row;
    }
  }


  &__button {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @media (min-width: 400px) {
      width: calc(50% - 8px);

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__button-icon {
    width: 16px;
    height: 16px;
    color: $c-black;
    margin-right: 8px;
  }
}
