@import 'src/global/variables';
@import 'src/global/typography.scss';

.event-details {
    display: flex;
    justify-content: center;
    margin-top: 64px;

    &__column {
        padding: 0 60px;
     
        &--calendar {
            display:flex;
            justify-content: center;
        }

        &--details {
            max-width: 500px;
            flex: 1 1 500px;
            display: flex;
            flex-direction: column;
        }
    }

    &__label {
        margin-bottom: 8px;
        display: block;
    }

    &__input {
        margin-top: 10px;
        margin-bottom: 20px;
        display: block;
    }

    &__date {
        @extend %new-h2;
        margin-bottom: 30px;
    }

    &__footer{
        margin-top:auto;
    }

    @include media-breakpoint-down('md') {
      &__column {
        padding: 0 3 * $mainsize;
      }
    }
    @include media-breakpoint-down('sm') {
        margin-top: 0;
        flex-direction: column;
        &__column {
            padding: 0;
          }
        > * {
            max-width: 100%;
            flex: 1 0 100%;
        }
        > * + * {
            margin-top: 40px;
        }
        &__footer{
            margin-top:40px;
        }
    }
}
