@import "src/global/variables";
@import "src/global/grid";

.partners {
  &__content {
    max-width: 674px;
    width: 100%;
  }

  &__header {
    margin-bottom: 16px;

    @include media-breakpoint-up("md") {
      margin-bottom: 8px;
    }
  }

  &__description {
    font-size: 1.7rem;
    line-height: 2.4rem;

    @include media-breakpoint-up("md") {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }
}
