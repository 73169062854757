@import 'src/global/variables.scss';
@import 'src/global/grid';

.password-change {
    &__wrapper {
        margin-top: 40px;
        margin-bottom: 42px;
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        & > div {
            flex: 1;
        }
    }
    &__button {
        padding: 12px 25px;
        margin-bottom: 16px;
        margin-left: 16px;
    }
    @include media-breakpoint-down('sm') {
        &__wrapper {
            flex-wrap: wrap;
            justify-content: flex-end;
            & > div {
                min-width: 100%;
            }
        }
    }
}
