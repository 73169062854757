//--------------------------------------------------
// [Transition]
//--------------------------------------------------

// eg: @include transition(all 0.4s $ease);
@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
}

@mixin square($a) {
  width: $a;
  height: $a;
}

%dombasic {
  content: " ";
  display: block;
  position: absolute;
}

%saveBottomButton {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: $mainsize * 2 0;
  text-align: center;
  background-color: rgba(248, 241, 239, 1);
}

@mixin topHeaderMobileShadow {
  background-color: $color-white;
  box-shadow: 0 -5px 10px 10px rgba($color-white, 1);
}

@mixin limitMax($lines, $height) {
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  max-height: $height;
}
