@import 'src/global/variables';
@import 'src/global/typography.scss';
@import 'src/global/styles';

.container {
    position: relative;
    border: 1px solid transparentize($color-black, 0.9);
    border-radius: 8px;
    padding: 8px 8px 8px 16px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--default {
        border: 1px solid $c-green;
    }
}
.content {
    span {
        display: inline-block;
    }
}

.label {
    font-weight: 500;
    margin-right: 10px;
    font-size: 1.6rem;
    & + span{
        font-size: 1.3rem;
    }
}

.number{
    white-space: nowrap;
}

.actions {
    flex-shrink: 0;
    margin-left: $mainsize;
    & > * {
        & + * {
            margin-left: 10px;
        }
    }

    @include media-breakpoint-down('md') {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        & > *:not(:last-child) {
            margin-top: $mainsize;
            & + * {
                margin-left: 0;
                margin-top: 0;
            }
        }
    }
}
