@import 'src/global/typography';
@import 'src/global/grid';

.text-with-point-section {
    p + ul {
        margin-top: 30px;
    }
    @include media-breakpoint-up('md') {
        ul {
            display: flex;
            flex-wrap: wrap;
            > li {
                flex: 0 0 50%;
                padding-right: 2 * $mainsize;
                &:last-of-type:first-of-type {
                    flex: 0 0 100%;
                }
            }
        }
    }
}
