@import "src/global/variables";
@import "src/global/typography.scss";
@import "src/global/styles";

.date-picker {
  &__month {
    margin-right: 0.5 * $mainsize;
  }

  &__navigation {
    display: flex;
    justify-content: center;

    &--previous,
    &--next {
      cursor: pointer;
      display: flex;
      align-items: end;
      position: relative;

      &::after {
        content: ' ';
        display: inline-block;
        border-left: 2px solid $c-black;
        border-top: 2px solid $c-black;
        width: 12px;
        height: 12px;
        position: absolute;
      }
    }

    &--previous {
      margin-right: 3 * $mainsize;

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        transform-origin: 100% 0;
        top: 9px;
        left: -7px;
      }
    }

    &--next {
      margin-left: 3 * $mainsize;

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(135deg);
        top: 11px;
        left: 1px;
      }
    }
  }

  @extend %new-top-menu;
}
