@import 'src/global/variables.scss';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.experience-container {
    &__calendar {
        margin: 50px 0 80px;
    }
    &__wrapper {
        margin: 40px 0 80px;
        &--with-background {
            > div {
                padding-bottom: 60px;
                padding-top: 60px;
            }
        }
        &--host {
            background: $c-silver;
            padding-left: 60px;
            padding-right: 60px;
        }
    }
    @include media-breakpoint-down('sm') {
        &__wrapper {
            margin: 20px 0 40px;
            &--with-background {
                > div {
                    padding-bottom: 40px;
                    padding-top: 40px;
                }
            }
            &--host {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
        &__calendar {
            margin: 30px 0 40px;
        }
    }
}
