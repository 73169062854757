@import "src/global/grid";

.messages-container {
  height: 100%;
  border-top: 1px solid $color-grey0;
  overflow-y: hidden;

  &__content {
    border-left: 1px solid $color-grey0;
    border-right: 1px solid $color-grey0;
  }

  &__details {
    height: 100%;
  }

  @include media-breakpoint-up("sm") {
    &__inner {
      display: flex;
    }

    &__details {
      flex: 1 0 320px;
      overflow: auto;
    }

    &__list {
      flex: 1 0 320px;
    }

    &__content {
      flex: 1 1 100%;
    }
  }
}
