@import "src/global/variables";
@import "src/global/grid";

.quotation {
  padding-top: 76px;
  padding-bottom: 52px;

  @include media-breakpoint-up("sm") {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  &__figure {
    position: relative;
    margin: 0 auto;
    width: clamp(220px, 70%, 280px);

    @include media-breakpoint-up("sm") {
      width: clamp(280px, 70%, 675px);
    }

    &::before {
      background-image: url("../../../../assets/icons/icon_quotes.svg");
      width: 64px;
      height: 45px;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.4;
      top: -20px;
      left: -35px;

      @extend %dombasic;

      @include media-breakpoint-up("sm") {
        width: 144px;
        height: 102px;
        top: -60px;
        left: -90px;
      }
    }
  }

  &__blockquote {
    font-size: 2.1rem;
    line-height: 3rem;

    @include media-breakpoint-up("sm") {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }

  &__figcaption {
    color: $color-grey3;
    font-size: 1.7rem;
    line-height: 2.4rem;
    margin-top: 16px;

    @include media-breakpoint-up("sm") {
      font-size: 1.9rem;
      line-height: 3.2rem;
    }
  }
}
