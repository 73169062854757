@import "src/global/grid";

.explore-page-card {
    position: relative;
    cursor: pointer;
    &__button {
        @include media-breakpoint-up("sm") {
            width: 321px;
        }
        width: 100%;
        align-self: flex-end;
    }

    &__button_wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        z-index:10;
        padding: 16px;
        @include media-breakpoint-up("md") {
            padding: 40px;
        }
        display: flex;
        justify-content: center;
    }

    &__image {
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 13.13%, rgba(255, 255, 255, 0) 70.75%);
    }
}
