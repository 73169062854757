@import "src/global/variables";
@import "src/global/grid";

.review-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-grey0;
  border-radius: 8px;
  padding: 24px;
  text-align: center;

  @include media-breakpoint-up("sm") {
    padding: 40px;
  }

  &__image {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      width: 120px;
      height: 120px;
      margin-bottom: 32px;
    }
  }

  &__name {
    color: $color-black;
    font-weight: $weight-medium;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 10px;

    @include media-breakpoint-up("sm") {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 18px;
    }
  }

  &__stars-wrapper {
    display: flex;
    margin-bottom: 26px;

    @include media-breakpoint-up("sm") {
      margin-bottom: 35px;
    }
  }

  &__star {
    width: 20px;
    height: 20px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__description {
    color: $color-grey4;
    font-size: 1.7rem;
    line-height: 2.4rem;

    @include media-breakpoint-up("sm") {
      font-size: 1.7rem;
      line-height: 2.6rem;
    }
  }
}
