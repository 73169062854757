@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.navbar {
    height: 80px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: $c-white;
    color: $c-black;
    width: 100%;
    z-index: 10;
    a {
        color: $c-black;
    }

    &--with-fixed {
        position: absolute;
        top: 0;
    }

    &--with-absolute {
        position: absolute;
        top: 0;
    }

    &--with-offset {
        @include media-breakpoint-up('md') {
            top: 72px;
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
    }

    &--transparent {
        background-color: transparent;
        color: $c-white;
        a {
            color: $c-white;
        }
    }

    &--dark {
        color: $c-black;
        a {
            color: $c-black;
        }
    }

    &--mobile-app {
        .navbar__search {
            @include media-breakpoint-down('xs') {
                max-width: none;
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down('md') {
            padding-left: $grid-padding-mobile;
            padding-right: $grid-padding-mobile;
        }
    }

    &__account-actions {
        display: flex;
        align-items: center;
        .navbar--with-embed & {
            & > *:last-child{
                margin-right:0;
            }
        }
    }

    &__account-link {
        font-size: 1.7rem;
        font-weight: $weight-medium;
        line-height: 2.4rem;
        cursor: pointer;
        margin-right: 24px;

        &:hover {
            opacity: 0.75;
        }
    }

    &__logo {
        @include media-breakpoint-down('sm') {
            flex: 0 0 31px;
            margin-right: $mainsize;
        }
    }

    &--with-search {
        .navbar__logo {
            @media screen and (max-width: 420px) {
                display: none;
            }
        }
    }

    &__search {
        width: 450px;
        max-width: 100%;

        @include transition((top 0.3s $ease, width 0.3s $ease));

        @include media-breakpoint-up('md') {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include media-breakpoint-only('md') {
            width: 40%;
        }

        &--prominent {
            @include media-breakpoint-up('md') {
                top: 106px;
                width: 650px;
            }
        }

        @include media-breakpoint-down('xs') {
            position: static;
            width: 100%;
            transform: none;
            max-width: calc(100% - 82px);
        }
    }
}
