@import "src/global/grid";

.main-page {
  position: relative;
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 96px;

  @include media-breakpoint-down("sm") {
    gap: 56px;
  }
}
