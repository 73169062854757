@import "src/global/variables";
@import "src/global/grid";

.our-values-card {
  background-color: $c-light-gray-2;
  padding: 32px 24px 24px;
  border-radius: 8px;

  &__name {
    color: $c-dark-orange;
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: $weight-medium;
    margin-bottom: 24px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
  }
}
