@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.columns {
    display: flex;
    align-items: flex-start;
    column-gap: 44px;
    width: 100%;

    @include media-breakpoint-down('xs') {
        flex-direction: column;
    }
    .-image {
        min-width: 200px;
        line-height: 0;
        label {
            display: block;
            margin-bottom: 8px;
        }

        @include media-breakpoint-down('xs') {
            width: 100%;
        }
    }
    .-name {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 100%;
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 40px;
    margin-bottom: 42px;

    & > *:not(button) {
        width: 100%;
    }

    .mapContainer {
        margin-bottom: 32px;
    }

    .geocoder {
        position: relative;
        z-index: 3;
        margin-bottom: 16px;
        & > p {
            font-size: 1.4rem;
            line-height: 114%;
            color: $c-black;
            font-family: $ff-default;
            font-weight: $weight-medium;
            margin-bottom: 8px;
        }
        :global(.mapboxgl-ctrl-geocoder) {
            width: 100%;
            box-shadow: none;
            max-width: none;
            font-family: $ff-default;
            display: flex;
            align-items: center;
            border: thin solid $c-light-gray;
            border-radius: 8px;
            font-size: 1.6rem;
            line-height: 107%;
            color: $c-black;
        }
        :global(.mapboxgl-ctrl-geocoder--icon-search) {
            position: relative;
            left: 9px;
            top: 0;
        }
        :global(.mapboxgl-ctrl-geocoder--icon-close),
        :global(.mapboxgl-ctrl-geocoder--icon-loading) {
            position: relative;
            right: 5px;
            top: 0;
        }
        :global(.mapboxgl-ctrl-geocoder--icon-close) {
            margin-top: 0;
        }
        :global(.mapboxgl-ctrl-geocoder--input) {
            padding: 10px;
            height: auto;
            outline: none;
        }
        :global(.suggestions) {
            border-radius: 8px;
            border: thin solid $c-light-gray;
            box-shadow: none;
        }
    }
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 16px;
}
