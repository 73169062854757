@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.our-values {
  padding-bottom: 48px;

  @include media-breakpoint-up("sm") {
    padding-bottom: 120px;
  }

  &__header {
    @extend %font-secondary-bold;
    margin-bottom: 24px;
    font-size: 2.7rem;
    line-height: 3.2rem;

    @include media-breakpoint-up("sm") {
      margin-bottom: 40px;
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
  }

  &__cards {
    @include media-breakpoint-up("sm") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      width: calc((100% - 2 * 26px) / 4);
      margin-bottom: 0;
    }
  }
}
