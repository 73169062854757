@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.account-actions {
    display: flex;

    &__buttons-wrapper {
        display: flex;
        align-items: center;
        padding-left: 2 * $mainsize;
        padding-right: $mainsize;
        margin: 0 $mainsize;
        border: 1px solid $c-white;
        border-radius: 100px;

        @include media-breakpoint-down('sm') {
            margin-right: 0;
        }
        @include media-breakpoint-down('xs') {
            border: none;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__host-button {
        @media all and (max-width: 480px) {
            display: none;
        }
    }

    &__button {
        padding: 0 2 * $mainsize;
        height: 48px;
        min-width: 48px;
        & + & {
            margin-left: $mainsize;
        }
    }

    &__icon {
        & + & {
            margin-left: $mainsize;
        }
    }
    @include media-breakpoint-down('sm') {
        &__icon {
            & + & {
                display: none;
            }
        }
        &__button {
            background: none;
            padding: 0;
            color: inherit;
        }
    }
}
