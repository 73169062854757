@import 'src/global/variables';
@import 'src/global/typography';
@import 'src/global/grid';

.event-card {
    position: relative;
    padding: 2 * $mainsize;
    width: 100%;
    display: flex;

    & + & {
        border-top: $border-light;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__image {
        cursor: pointer;
        img {
            border-radius: 4px;
            width: 100%;
        }
    }

    &__description {
    }

    &--active {
        z-index: 2;
        background: rgba(0, 0, 0, 0.05);
    }

    &--list {
        .event-card__carousel {
            height:100%;
        }
        .event-card__image {
            margin-right: 2 * $mainsize;
            flex: 0 0 50%;
            max-width: 50%;
            figure {
                height: 100%;
            }
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        .event-card__description {
            flex: 0 1 50%;
            max-width: 50%;
            height: auto;
        }
    }

    &--card {
        width: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        background: $c-very-light-gray;

        .event-card__image {
            max-width: none;
            margin-right: -2 * $mainsize;
            margin-left: -2 * $mainsize;
            margin-top: -2 * $mainsize;
        }
        .event-card__description {
            margin-top: 2 * $mainsize;
            max-width: 100%;
        }

        & + & {
            border-top: none;
        }
    }

    @include media-breakpoint-only('xs') {
        &--list {
            display: block;
            padding: 0 0 2 * $mainsize 0;
            margin-bottom: 2 * $mainsize;
            border-bottom: $border-light;
            & + & {
                border-top: none;
            }
            .event-card__image {
                max-width: 100%;
                margin-right: 0;
            }
            .event-card__description {
                margin-top: 2 * $mainsize;
                max-width: 100%;
            }
        }
    }
}
