@import "src/global/variables";
@import "src/global/grid";

.for-guides{
    &__wrapper{
        margin-bottom: 80px;
    }
    @include media-breakpoint-down("sm") {
        &__wrapper{
            margin-bottom: 40px;
        }
    }
}