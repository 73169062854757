@import "src/global/variables";
@import "src/global/grid";
@import "src/global/typography";

.our-story {
  padding-bottom: 48px;

  @include media-breakpoint-up("sm") {
    padding-bottom: 120px;
  }

  &__header {
    @extend %font-secondary-bold;
    margin-bottom: 24px;
    font-size: 2.7rem;
    line-height: 3.2rem;

    @include media-breakpoint-up("md") {
      margin-bottom: 40px;
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
  }

  &__origin-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__flag {
    width: 24px;
    height: 26px;
    margin-right: 8px;

    @include media-breakpoint-up("md") {
      width: 32px;
      height: 34px;
    }
  }

  &__origin {
    font-size: 1.7rem;
    line-height: 2.4rem;
    font-weight: $weight-medium;
    opacity: 0.7;

    @include media-breakpoint-up("md") {
      font-size: 1.9rem;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;

    &--2 {
      flex-direction: column-reverse;
    }

    &--3 {
      .our-story__photos-wrapper {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up("md") {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__paragraph {
    font-size: 1.7rem;
    line-height: 2.4rem;
    width: 100%;
    margin-bottom: 24px;

    @include media-breakpoint-up("md") {
      font-size: 2.2rem;
      line-height: 3.2rem;
      width: calc((100% - 128px) / 2);
      padding: 36px 64px;
      margin-bottom: 0;
    }
  }

  &__images-wrapper {
    width: 100%;
    margin-bottom: 24px;

    @include media-breakpoint-up("md") {
      width: calc((100% - 128px) / 2);
      padding: 36px 64px;
      margin-bottom: 0;
    }
  }

  &__images-row {
    display: flex;

    &--1 {
      margin-bottom: 16px;
      justify-content: flex-end;
    }

    &--2 {
    }

    &--3 {
      margin-bottom: 16px;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  &__image {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;

    &--1 {
      margin-right: 8px;
      width: calc((100% - 16px) / 2);
      height: 184px;

      @include media-breakpoint-up("md") {
        height: 192px;
      }
    }

    &--2 {
      margin-left: 8px;
      width: calc((100% - 16px) / 2);
      height: 184px;
      @include media-breakpoint-up("md") {
        height: 192px;
      }
    }

    &--3 {
      display: none;
      margin-right: 8px;

      @include media-breakpoint-up("md") {
        display: block;
        height: 224px;
      }
    }

    &--4 {
      height: 184px;
      width: 100%;

      @include media-breakpoint-up("md") {
        margin-left: 8px;
        height: 224px;
        width: auto;
      }
    }

    &--5 {
      margin-right: 8px;
      width: calc((100% - 16px) / 2);
      height: 168px;

      @include media-breakpoint-up("md") {
        width: auto;
        max-width: 192px;
        height: 232px;
      }
    }

    &--6 {
      margin-left: 8px;
      width: calc((100% - 16px) / 2);
      height: 168px;

      @include media-breakpoint-up("md") {
        max-width: 256px;
        height: 208px;
      }
    }

    &--7 {
      display: none;

      @include media-breakpoint-up("md") {
        display: block;
        max-width: 280px;
        height: 184px;
      }
    }

    &--8 {
      width: 100%;
      height: 200px;

      @include media-breakpoint-up("md") {
        height: 280px;
      }
    }
  }
}
