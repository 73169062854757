@import "src/global/variables";
@import "src/global/grid.scss";
@import "src/global/typography.scss";

.how-it-works {
  padding-top: 56px;
  padding-bottom: 40px;
  background-color: $c-base;
  overflow: hidden;

  @include media-breakpoint-up("sm") {
    padding-top: 123px;
    padding-bottom: 88px;
  }

  &__header {
    margin-bottom: 16px;

    @include media-breakpoint-up("sm") {
      text-align: center;
      margin-bottom: 72px;
    }
  }

  &__description_sc {
    text-align: center;
    padding-top: 40px;
    font-size: 19px;
    line-height: 30px;
  }

  &__points-wrapper {
    @include media-breakpoint-up("sm") {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 60px;
      overflow: hidden;
    }
  }

  &__point {
    position: relative;
    width: 100%;
    padding-left: 40px;

    &:not(:last-child) {
      padding-bottom: 24px;
      padding-right: 0;
    }

    @include media-breakpoint-up("sm") {
      padding-left: 0;
      padding-right: 72px;

      &:not(:last-child) {
        padding-bottom: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
      left: 10px;
      background-color: $color-grey1;

      @include media-breakpoint-up("sm") {
        top: 31px;
        width: calc(100% + 60px);
        left: calc(50% - 96px);
        height: 1px;
      }
    }

    &:first-of-type {
      &::before {
        @include media-breakpoint-up("sm") {
          left: calc(50% - 96px);
          width: calc(100% + 80px);
        }
      }
    }
  }

  &__point-arrow {
    position: absolute;
    top: 100%;
    left: 11px;
    transform: translate(-50%, -50%) rotate(90deg);

    @include square(24px);

    @include media-breakpoint-up("sm") {
      transform: translate(-50%, -50%) rotate(0deg);
      top: 31px;
      right: 15%;
      left: unset;
    }
  }

  &__point-number {
    position: relative;
    @extend %font-primary-medium;
    color: $c-dark-orange;
    font-size: 4.8rem;
    line-height: 4.8rem;
    margin-bottom: 8px;

    @include media-breakpoint-up("sm") {
      font-size: 5.6rem;
      line-height: 5.6rem;
      margin-bottom: 32px;
    }
  }

  &__point-name {
    @extend %new-h3;
    margin-bottom: 8px;
  }

  &__point-description {
    @extend %new-h4;
  }
}

.how-it-works__bg-wrapper {
  background-color: $c-base;

  .how-it-works__bg {
    width: 100%;
    max-height: 576px;
    object-fit: cover;
    object-position: center;
  }
}
