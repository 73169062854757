@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.container {
    margin-top: 40px;
    margin-bottom: 98px;
}

.containerInner {
    display: grid;
    grid-template-columns: 380px auto;
    gap: 32px;
    grid-template-areas:
        'summary-tab payments-info'
        'summary-tab card-details';

    @include media-breakpoint-down('sm') {
        display: flex;
        flex-direction: column;
    }
}

.summaryTabArea {
    grid-area: summary-tab;
}

.PaymentsExternalAccountsList {
    grid-area: payments-info;
}

.cardDetails {
    grid-area: card-details;
}
.stripe-status {
    margin-bottom: 16px;
    p {
        @extend %new-small-paragraph;
    }
}

.highlight {
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(210, 80, 52, 0.05);
    padding: 16px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    p {
        flex: 1;
        font-size: 1.7rem;
        line-height: 1.6rem;
        opacity: 0.8;
    }
    span {
        font-size: 2.4rem;
        line-height: 1;
        font-weight: 500;
    }
}
