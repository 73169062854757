@import "src/global/typography";

.type-message-input {
  background: $c-white;
  padding: 3 * $mainsize 3 * $mainsize;
}

.type-message-input__label {
  margin-bottom: 0;
}

.send-button {
  height: 4 * $mainsize;
  flex: 0 0 4 * $mainsize;
  background: $color-bg;
  border-radius: 100%;
  color: $c-white;
  margin-right: $mainsize;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &__loader {
  }

  &__icon {
    width: 10px;
    height: 12px;
  }
}

@include media-breakpoint-down("sm") {
  .type-message-input {
    padding: 2 * $mainsize 2 * $mainsize;
  }
}
