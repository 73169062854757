@import "src/global/variables";

.container {
    width: 100%;
    display: flex;
    &.theme-default {
        --height: 32px;
        --font-size: 1.4rem;
        --border-color: rgba(0,0,0,0.1);
        --border-color-active: #CECDCD;
        --background-active: #FBFAF9;
    }
    div {
        width: 1px;
        background-color: var(--border-color);
        &.active {
            background-color: var(--border-color-active);
        }
    }
    button {
        min-height: var(--height);
        font-size: var(--font-size);
        flex: 1;
        border-top-width: thin;
        border-bottom-width: thin;
        border-left-width: 0;
        border-right-width: 0;
        border-style: solid;
        border-color: var(--border-color);
        &:first-of-type {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-left-width: thin;
        }
        &:last-of-type {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-right-width: thin;
        }
        &.active {
            border-color: var(--border-color-active);
            background-color: var(--background-active);
            font-weight: 500;
        }
    }
}