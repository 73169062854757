@import 'src/global/variables';
@import 'src/global/typography';
@import 'src/global/grid';

.experience-footer {
    display: flex;
    justify-content: space-between;
    padding: $mainsize 0;
    width: 100%;

    &__column {
        flex: 1 1 100%;
    }

    &__image-link {
        position: relative;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 2 * $mainsize;
        border-color: $c-white;
        color: $c-white;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $c-black;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 2px solid;
            border-radius: 50%;
        }
    }

    &__image {
        width: 53px;
        height: 53px;
        flex: 0 0 53px;
    }

    &__cta {
        margin: $mainsize 0;
        & + & {
            margin-left: $mainsize;
        }
    }

    &__additional-info {
        @extend %new-extra-small-text;
        white-space: nowrap;
        a {
            @extend %font-primary-medium;
        }
    }
    &__title {
        @extend %new-h3;
    }
    &__rating {
        margin-left: 2 * $mainsize;
    }
    &__price {
        p {
            @extend %new-h3;
        }
    }
    @include media-breakpoint-up('sm') {
        &__price {
            display: flex;
            align-items: center;
            p {
                margin-right: 2 * $mainsize;
            }
        }
        &__column {
            &--host-info {
                display: flex;
                align-items: center;
                align-self: center;
            }
            &--event-info {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
    @include media-breakpoint-down('xs') {
        &__column {
            &--host-info {
                display: none;
            }
        }

        &__cta {
            width: 100%;
            & + & {
                margin-left: 0;
            }
        }

        &__additional-info {
            white-space: normal;
        }
    }
}
