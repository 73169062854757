@import "src/global/variables";
@import "src/global/typography";

.map-gl-pin {
  height: 20px;
  width: 20px;
  filter: grayscale(100%);

  img {
    // transform: translate3d(-10px, 0, 0);
  }

    &--hovered {
        z-index: 2;
        filter: none;

        .map-gl-pin-detailed {
            background-color: $c-white;
            border-color: rgba($c-black, 0.18);
        }
    }

    &--was-clicked {
        .map-gl-pin-detailed {
            background-color: rgb(231, 231, 231);
        }
    }

  &--active {
    z-index: 2;
    filter: none;

      .map-gl-pin-detailed {
        background-color: $color-bg;
        border-color: transparent;

          & > * {
              color: $c-white !important;
          }
      }
  }

  &--disabled {
    .map-gl-pin__button {
      cursor: default;
    }
  }

  &__icon {
    height: 50px;
    width: 50px;
  }
}

.map-gl-pin-detailed {
  height: 30px;
  padding: 4px 7px;
  background-color: $c-white;
  border-radius: 100px;
  border: 1px solid transparent;
  filter: drop-shadow(0 8px 12px rgba($c-black, 0.1));
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    width: 22px;
    height: 22px;
    margin-right: 7px;
  }
}
