@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography.scss';

.experiences-grid{
    &__header {
      @extend %new-h2;
      margin-bottom: 3 * $mainsize;
    }
    &__wrapper{
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      margin: 0 -1 * $mainsize;
      > * {
        padding: 0 $mainsize 2 * $mainsize;
        flex: 0 1 100%;
      }
      @include media-breakpoint-up('sm') {
          > * {
              flex: 0 1 50%;
          }
      }
      @include media-breakpoint-up('md') {
          > * {
              flex: 0 1 33%;
          }
      }
      @include media-breakpoint-up('lg') {
          > * {
              flex: 0 1 25%;
          }
      }
    }
}
