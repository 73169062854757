.mapboxgl-popup-anchor-left {
    top: 5px;
}

.mapboxgl-popup-anchor-right {
    top: 5px;
}

.mapboxgl-popup-anchor-top {
    top: 25px;
    left: 20px;
}

.mapboxgl-popup-anchor-bottom {
    top: -15px;
    left: 20px;
}

.mapboxgl-popup-anchor-bottom-left {
    top: -15px;
}

.mapboxgl-popup-anchor-bottom-right {
    top: -15px;
}

.mapboxgl-popup-anchor-top-left {
    top: 25px;
}

.mapboxgl-popup-anchor-top-right {
    top: 25px;
}

.mapboxgl-popup-tip {
    display: none;
}