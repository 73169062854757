@import "src/global/typography";

.empty-conversation {
  &__text {
    opacity: 0.6;
    margin: 0 0 1.75 * $mainsize $mainsize;

    @extend %new-small-paragraph;
  }

  &__item {
    margin-bottom: $mainsize;
  }
}
