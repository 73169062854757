@import 'src/global/variables';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.social-connect {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $c-very-dark-gray;
    border-radius: $mainsize;
    height: 48px;
    transition: border-color 0.2s;
    &:hover {
        border-color: $c-light-gray;
    }
    img,
    svg {
        max-height: 32px;
    }

    &__separator {
        margin: $mainsize 0;
        color: $c-light-gray;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: block;
            background-color: $c-white;
            padding: $mainsize 2.5 *  $mainsize;
            z-index: 1;
        }
        &::after {
            content: '';
            position: absolute;
            top: calc(50% + 1px);
            height: 1px;
            width: 100%;
            left: 0;
            background-color: $c-light-gray;
        }
    }
}
