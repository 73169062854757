@import 'src/global/variables';
@import 'src/global/grid';
@import 'src/global/typography';

.experience-preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        position: relative;
        width: 100%;
        padding: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
    }

    @include media-breakpoint-up('md') {
        max-width: 320px;
    }

    &__info {
        margin-bottom: 24px;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title,
 
    &__protection-title {
        @extend %new-h3;
    }

    &__title,
    &__protection-title {
        margin-bottom: 16px;
    }

    &__close {
        width: 14px;
        height: 14px;
        stroke: rgba($c-black, 0.6);
    }

    &__image {
        margin-bottom: 8px;
        max-height:200px;
    }

    &__details-block {
        &:not(:last-child) {
            margin-bottom: 16px;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        h3 {
            @extend %new-small-text;

            opacity: 0.7;
        }

        a {
            font-weight: $weight-medium;
            opacity: 0.7;
            text-decoration: underline;
        }

        span {
            @extend %new-small-text;

            display: block;
            font-weight: $weight-medium;
        }
    }

    &__description {
        @extend %new-small-paragraph;
    }

    &__status-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        span{
            @extend %font-primary-medium;
            font-size: 1.7rem;
        }
    }

 
    &__payment {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                @extend %new-small-text;

                display: block;
                font-weight: $weight-medium;
                opacity: 0.7;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &:last-child {
                span {
                    @extend %new-h3;

                    font-size: 1.7rem;
                    opacity: 1;
                }
            }
        }
    }

    &__ctas-wrapper {
        position: sticky;
        bottom: 0;
        background: $c-white;
        padding: 0 2 * $mainsize;
        box-shadow: 0px -15px 35px rgba(0, 0, 0, 0.05);
        p {
            @extend %new-extra-extra-small-text;
        }
        & > * {
            margin: 2 * $mainsize 0 0 0;
            &:last-child{
                margin-bottom: 2 * $mainsize;
            }

            & + * {
                margin-top: $mainsize;
            }
        }
    }

    &__details,
    &__payment,
    &__protection {
        margin-bottom: 24px;
    }

    &__protection {
        // border-top: 1px solid rgba($c-black, 0.2);
        padding-top: 24px;
    }

    &__protection-text {
        @extend %new-small-paragraph;
    }
}
