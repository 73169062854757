@import 'src/global/variables.scss';
@import 'src/global/grid.scss';
@import 'src/global/typography.scss';

.experience-location {
    border-radius: $mainsize;
    &__map {
        height: 630px;
    }

    * {
        cursor: default !important;
    }

    &__header {
        @extend %new-h2;
        margin-bottom: 3 * $mainsize;
    }

    @include media-breakpoint-down('sm') {
        &__map {
            height: 380px;
        }
    }
}